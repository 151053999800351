/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import "./styles/style.less";
import { observer, inject } from "mobx-react";
import classNames from "classnames";

import { authService, webSocketService } from "./services";

import { Modal } from "./Components/General";
import Wallet from "./Components/Wallet";

class App extends Component {
  constructor() {
    super();
    this.setViewportHeight = this.setViewportHeight.bind(this);
  }

  componentDidMount() {
    authService.keycloak.init({ onLoad: 'login-required', checkLoginIframe: false })
      .then(authenticated => {
        if (authenticated) {
          this.init(authService.keycloak.token);
        }
      })
      .catch(error => {
        console.error('Keycloak initialization error:', error);
      });

    this.setViewportHeight();
    window.addEventListener("resize", this.setViewportHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setViewportHeight);
    this.cleanup();
  }

  setViewportHeight() {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight * 0.01}px`
    );
  }

  async updateWallet(user) {
    const { walletStore, emissionsStore, userStore, exchangeStore } = this.props;

    try {
      const currencies = await walletStore.GetCurrencies();
      walletStore.setCurrencyList(currencies);

      if (currencies && currencies.length > 0) {
        walletStore.setActiveCurrency(currencies[0]);
      } else {
        console.error("No active currency available to request balance and history");
      }

      if (userStore.hasRole('emitter')) {
        await emissionsStore.GetEmissionsHistory();
        if (!emissionsStore.emissionViewCurrency && currencies && currencies.length > 0) {
          emissionsStore.setEmissionViewCurrency(currencies[0]);
        }
      }

      if (userStore.hasRole('exchange')) {
        await exchangeStore.GetExchangeId();
        const exchangeCurrencies = await exchangeStore.GetExchangeCurrencyList();
        exchangeStore.setExchangeCurrencyList(exchangeCurrencies);

        if (exchangeCurrencies && exchangeCurrencies.length > 0) {
          const initialExchangeCurrency = exchangeStore.exchangeViewCurrency || exchangeCurrencies[0];
          exchangeStore.setExchangeViewCurrency(initialExchangeCurrency);
          await exchangeStore.GetExchangeBills(initialExchangeCurrency);
        }
      }
    } catch (error) {
      console.error("Error updating wallet:", error);
    }
  }

  init(token) {
    const { walletStore, emissionsStore } = this.props;
    if (token) {
      authService.setAccessToken(token);
      this.props.userStore.GetUserData().then((user) => {
        authService.setUser(user);

        webSocketService.connect(
          user.walletID,
          this.props.alertsStore,
          walletStore,
          emissionsStore
        );

        webSocketService.onWalletReady(() => {
          this.updateWallet(user);
        });
      }).catch((error) => {
        console.error('Error retrieving user data:', error);
      });
    } else {
      this.cleanup();
      this.props.userStore.GetUserData();
    }
  }
  
  cleanup() {
    webSocketService.close();
  }

  render() {
    const { userStore } = this.props;

    if (userStore.loading) {
      return <div>Loading...</div>;
    }

    let walletOverlayClass = classNames({
      app: true,
      "is--visible": userStore.user.userID,
    });

    return (
      <div className={walletOverlayClass}>
        <div className="fixed-bg"></div>
        <Wallet />
        <Modal
          {...this.props.alertsStore.alert}
          visible={!!this.props.alertsStore.alert.type}
        />
      </div>
    );
  }
}

export default inject("alertsStore", "userStore", "walletStore", "emissionsStore", "exchangeStore")(observer(App));
