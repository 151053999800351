import React from 'react';

function TableRow(props) {
  return (
    <tr {...props} className={`table-row ${props.className}`}>
      {props.children}
    </tr>
  );
}

export default TableRow;
