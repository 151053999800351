/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import Select from "react-select";

import { ListGroup } from "./General";
import WalletContent from "./Wallet/WalletContent";
import ViewHistory from "./ViewHistory";
import ViewMetrics from "./ViewMetrics";
import ViewGateways from "./ViewGateways";
import ViewEmissions from "./ViewEmissions";
import ViewExchange from "./ViewExchange";
import ViewPay from "./ViewPay";
import ViewGet from "./ViewGet";

import Header from "./Header";
import Button from "./General/Button";
import BalanceBanner from "./BalanceBanner";
import numeral from "numeral";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavId: "day",
      showUserSettings: false,
      showHistoryView: false,
      walletContentVisible: false,
      currency: "",
      newCurrency: null,
    };

    this.togglePayViewVisibility = this.togglePayViewVisibility.bind(this);
    this.toggleGetViewVisibility = this.toggleGetViewVisibility.bind(this);
    this.hideModals = this.hideModals.bind(this);
  }

  async sharePublicKey(code) {
    const el = document.createElement("textarea");
    el.value = code;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  showHistory = () => {
    this.props.styleStore.toggleVisibleView("history");
    this.setState({
      showHistoryView: true,
    });
  };

  hideHistory = () => {
    this.props.styleStore.toggleVisibleView("");
    this.setState({ 
      showHistoryView: false,
    });
  };

  togglePayViewVisibility(props = {}) {
    this.props.walletStore.setPayViewProps(props);
    this.props.styleStore.togglePayViewVisibility();
  }

  toggleGetViewVisibility() {
    this.props.styleStore.toggleGetViewVisibility();
  }

  hideModals() {
    this.props.styleStore.hideModals();
    
    setTimeout(() => {
      this.props.walletStore.clearPayViewProps();
    }, 900);
  }

  render() {
    const { activeView, walletStore, styleStore, userStore } = this.props;
    const userData = {
      ...this.props.userStore.user,
      ...walletStore.walletInfo,
    };
    const activeCurrency = walletStore.activeCurrency;
    const walletBalance = numeral(
      walletStore.walletInfo.balanceList?.[walletStore.activeCurrency] || 0
    ).format("0.00") +
      " " +
      walletStore.activeCurrency?.toUpperCase();

    const values = {
      profile: [
        { label: "NAME", value: "userName" },
        { label: "ID NUMBER", value: "userID" },
        { label: "KSI CASH WALLET CREATED", value: "created" },
      ],
      publicKey: [
        {
          label: "",
          value: "walletID",
        },
      ],
    };

    return (
      <div ref={this.props.innerRef} className="dashboard">
        <Header
          togglePayViewVisibility={this.togglePayViewVisibility}
          toggleGetViewVisibility={this.toggleGetViewVisibility}
          hideModals={this.hideModals}
          userData={userData}
          showPayView={styleStore.showPayView}
          showGetView={styleStore.showGetView}
        />
        {userStore.hasRole('admin') && (
          <div
            className={`metrics banner no-bg ${
              activeView === "metrics" ? "banner--visible" : "banner--hidden"
            }`}
          >
            <ViewMetrics activeView={activeView} />
            <ViewGateways activeView={activeView} />
          </div>
        )}
        {userStore.hasRole("emitter") && (
          <div
            className={`emission banner no-bg ${
              activeView === "emission" ? "banner--visible" : "banner--hidden"
            }`}
          >
            <ViewEmissions
              isMobile={this.props.isMobile}
              isFullView={activeView === "myWallet"}
              setActiveView={(view) => this.props.setActiveView(view)}
            />
          </div>
        )}
        {userStore.hasRole("exchange") && (
          <div
            className={`exchange banner no-bg ${
              activeView === "exchange" ? "banner--visible" : "banner--hidden"
            }`}
          >
            <ViewExchange 
              activeView={activeView}
              togglePayViewVisibility={(props) => this.togglePayViewVisibility({ ...props, isViewExchange: true })}
            />
          </div>
        )}

        <ViewPay
          isMobile={this.props.isMobile}
          visible={styleStore.showPayView}
          balance={walletBalance}
          hidePayView={this.hideModals}
          {...walletStore.payViewProps}
        />

        <ViewGet
          visible={styleStore.showGetView}
          hideGetView={this.hideModals}
        />

        <BalanceBanner activeView={activeView} userData={userData} />

        <div className="two-col">
          <div
            className={`transactions banner ${
              activeView === "transactions"
                ? "banner--visible"
                : (activeView && "banner--hidden") || ""
            }`}
          >
            <h1>Transaction history</h1>
            <ViewHistory
              isMobile={this.props.isMobile}
              isFullView={activeView === "transactions"}
            />
            <Button
              cyber
              transparent
              label="See more"
              onClick={() => this.props.setActiveView("transactions")}
            />
          </div>
          <div
            className={`wallet-bills banner ${
              activeView === "myWallet"
                ? "banner--visible"
                : (activeView && "banner--hidden") || ""
            }`}
          >
            <h1>My Wallet Bills</h1>
            <WalletContent
              isMobile={this.props.isMobile}
              isFullView={activeView === "myWallet"}
            />
            <Button
              cyber
              transparent
              label="See more"
              onClick={() => this.props.setActiveView("myWallet")}
            />
          </div>
          {activeView === "profile" && (
            <div className="banner-profile-wrap banner--visible">
              <div className="profile banner">
                <h1>Profile</h1>
                <div className="view-user">
                  <div className="content settings-wrapper flex__col">
                    <ListGroup items={values.profile} data={userData} />

                    <div className="w-50 pad__r-24 z-index-100">
                      <span className="select__label">CURRENCY</span>
                      <Select
                        defaultValue={{
                          label: activeCurrency?.toUpperCase(),
                          value: activeCurrency,
                        }}
                        classNamePrefix="select"
                        menuPlacement="auto"
                        options={walletStore?.currencyList.map((currency) => {
                          return {
                            value: currency,
                            label:
                              currency === ""
                                ? "KSI"
                                : currency.toUpperCase(),
                          };
                        })}
                        onChange={(v) => {
                          walletStore.setActiveCurrency(v.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="profile banner">
                <h1>Wallet ID</h1>
                <div className="view-user">
                  <div className="content column settings-wrapper">
                    <ListGroup
                      items={values.publicKey}
                      data={userData}
                      isWide={true}
                    />
                    <Button
                      cyber
                      transparent
                      label="Copy my wallet ID"
                      className="copy-key"
                      onClick={() => this.sharePublicKey(userData.walletID)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default inject("styleStore", "userStore", "walletStore")(observer(Dashboard));
