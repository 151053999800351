import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

Button.propTypes = {
  children: PropTypes.element,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(["primary", "secondary", "link", "icon"]),
  working: PropTypes.bool,
  workingText: PropTypes.string,
  big: PropTypes.bool,
  small: PropTypes.bool,
  url: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  className: PropTypes.string,
  target: PropTypes.string,
  lightLink: PropTypes.bool,
  cyber: PropTypes.bool,
  tab: PropTypes.bool,
  label: PropTypes.string,
  transparent: PropTypes.bool,
};

export default function Button(props) {
  const className = classNames(
    "button",
    {
      [`button--${props.variant}`]: props.variant,
      "button--big": props.big,
      "button--small": props.small,
      "button--tab": props.tab,
      "button--working": props.working,
      [`button--${props.brand}`]: props.brand,
      "button--link-light": props.lightLink,
      "button--cyber": props.cyber,
      "button--transparent": props.transparent
    },
    props.className
  );

  const handleButtonClick = (event) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const TagName = props.url ? "a" : "button";

  return (
    <TagName
      {...(props.target && { target: props.target })}
      className={className}
      onClick={handleButtonClick}
      type={props.type}
      disabled={props.disabled}
      href={props.url ? props.url : undefined}
    >
      {props.working && props.workingText ? (
        props.workingText
      ) : props.cyber ? (
        <>
          {props.label}
          <span aria-hidden className="button__layer">
            {props.label}
          </span>
        </>
      ) : (
        props.children
      )}
      <span className="spinner" />
    </TagName>
  );
}
