/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import { observable, action, makeObservable } from 'mobx';

export default class StyleStore {
  visibleView = '';
  walletContentToggled = false;
  changeSubs = [];
  showPayView = false;
  showGetView = false;
  payViewActiveTab = 'qr';

  constructor() {
    makeObservable(this, {
      visibleView: observable,
      walletContentToggled: observable,
      showPayView: observable,
      showGetView: observable,
      payViewActiveTab: observable,
      toggleVisibleView: action,
      hideWalletContent: action,
      showWalletContent: action,
      subscribe: action, 
      togglePayViewVisibility: action,
      toggleGetViewVisibility: action,
      hideModals: action,
      setPayViewActiveTab: action,
    });
  }

  toggleVisibleView(type) {
    this.visibleView = type;
  }

  hideWalletContent() {
    this.walletContentToggled = false;
  }

  showWalletContent() {
    this.changeSubs.forEach(cb => {
      cb();
    })
    this.walletContentToggled = true;
  }

  subscribe(cb) {
    this.changeSubs.push(cb);
  }

  togglePayViewVisibility() {
    document.body.classList.add("fixed");
    this.toggleVisibleView("pay");
    this.showGetView = false;
    this.showPayView = true;
  }

  toggleGetViewVisibility() {
    document.body.classList.add("fixed");
    this.toggleVisibleView("get");
    this.showPayView = false;
    this.showGetView = true;
  }

  hideModals() {
    document.body.classList.remove("fixed");
    this.toggleVisibleView("");
    this.showPayView = false;
    this.showGetView = false;
  }

  setPayViewActiveTab(tab) {
    this.payViewActiveTab = tab;
  }
}
