import React from "react";

import classNames from "classnames";
import { useField, useFormikContext } from "formik";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";

export default function InputSelect(props) {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props.name);
  const [{ setTouched }] = useField(field.name);

  const handleInputChange = (inputValue, actionMeta, e) => {
    const val = inputValue?.value
      ? inputValue.value
      : props.inputType === "number"
      ? !inputValue
        ? 0
        : inputValue
      : inputValue;

    props.onChange?.(actionMeta, val);

    setFieldValue(field.name, val);
  };

  const className = classNames(
    "select",
    { "select--error": props.error },
    props.className
  );

  const customStyles = {
    input: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#ffffff",
      };
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontSize: 18,
      };
    },
  };

  const optionsValue = props.options?.find(
    (option) => option?.value === field?.value
  );

  const { inputType } = props;

  const Input = (props) => {
    return <components.Input {...props} type={inputType || "text"} />;
  };

  return (
    <div className={className} data-testid={props.testId || "select"}>
      {props.label && <label className="select__label">{props.label}</label>}
      <CreatableSelect
        className="select__inner"
        styles={customStyles}
        placeholder={props.placeholder}
        name={field.name}
        onChange={handleInputChange}
        onBlur={setTouched}
        options={props.options}
        menuPlacement="auto"
        classNamePrefix="select"
        components={{ IndicatorSeparator: null, Input }}
        isDisabled={props.disabled}
        isClearable
        value={
          optionsValue
            ? optionsValue
            : field.value
            ? { value: field.value, label: field.value }
            : inputType === "number"
            ? 0
            : ""
        }
      />
      {props.error && props.error.length > 0 && (
        <span data-testid="select__error" className="select__error">
          {props.error}
        </span>
      )}
    </div>
  );
}
