/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import UserStore from './userStore';
import StyleStore from './styleStore';
import WalletStore from './walletStore';
import AlertsStore from './alertsStore';
import EmissionsStore from './emissionsStore';
import ExchangeStore from './exchangeStore';

export default class RootStore {
  constructor() {
    this.userStore = new UserStore();
    this.styleStore = new StyleStore();
    this.walletStore = new WalletStore();
    this.alertsStore = new AlertsStore();
    this.emissionsStore = new EmissionsStore();
    this.exchangeStore = new ExchangeStore();
  }
}
