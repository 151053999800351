/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import classNames from "classnames";
import { observer, inject } from "mobx-react";
import { requestService, authService } from "../services";

import Logo from "./../styles/assets/logo_white.svg";
import { ReactComponent as Toggle } from "./../styles/assets/back_arrow.svg";
import { ReactComponent as DashboardIcon } from "./../styles/assets/menuIcons/dashboard.svg";
import { ReactComponent as Transactions } from "./../styles/assets/menuIcons/transactions.svg";
import { ReactComponent as WalletIcon } from "./../styles/assets/menuIcons/wallet.svg";
import { ReactComponent as Profile } from "./../styles/assets/menuIcons/profile.svg";
import { ReactComponent as LogOut } from "./../styles/assets/menuIcons/log_out.svg";
import { ReactComponent as Swap } from "./../styles/assets/menuIcons/swap.svg";
import { ReactComponent as Emissions } from "./../styles/assets/menuIcons/emissions.svg";
import { ReactComponent as Exchange } from "./../styles/assets/menuIcons/exchange.svg";

class SemiModal extends Component {
  handleNavigation = (view) => {
    if (this.props.activeView !== view) {
      window.scrollTo(0, 0);
      this.props.isMobile && this.props.toggleSemiModal(false);
      this.props.setActiveView(view);
    }
  };

  render() {
    const { activeView, hasRole } = this.props;

    return (
      <div
        className={classNames("semimodal-wrap", {
          open: this.props.isSemiModalOpen,
        })}
      >
        <div
          className="toggle"
          onClick={() =>
            this.props.toggleSemiModal(!this.props.isSemiModalOpen)
          }
        >
          <Toggle />
        </div>
        <div className="semimodal">
          <div className="inner">
            <div className="logo-wrap">
              {this.props.title || (
                <img className="logo" src={Logo} alt="KSI logo" />
              )}
            </div>
            {this.props.userName?.trim() && (
              <div className="user-name">Hey, {this.props.userName}!</div>
            )}
            <div className="nav-links">
              <div
                className={`nav-link ${!activeView ? "active" : ""}`}
                onClick={() => {
                  this.handleNavigation(null);
                }}
              >
                <DashboardIcon />
                Dashboard
              </div>
              {hasRole('admin') && (
                <div
                  className={`nav-link ${
                    activeView === "metrics" ? "active" : ""
                  }`}
                  onClick={() => {
                    this.handleNavigation("metrics");
                  }}
                >
                  <Swap />
                  Metrics
                </div>
              )}
              {hasRole('emitter') && (
                <div
                  className={`nav-link ${
                    activeView === "emission" ? "active" : ""
                  }`}
                  onClick={() => {
                    this.handleNavigation("emission");
                  }}
                >
                  <Emissions />
                  Emission
                </div>
              )}
              {hasRole('exchange') && (
                <>
                  <div
                    className={`nav-link ${
                      activeView === "exchange" ? "active" : ""
                    }`}
                    onClick={() => {
                      this.handleNavigation("exchange");
                    }}
                  >
                    <Exchange />
                    Exchange
                  </div>
                </>
              )}
              <div
                className={`nav-link ${
                  activeView === "myWallet" ? "active" : ""
                }`}
                onClick={() => {
                  this.handleNavigation("myWallet");
                  this.props.styleStore.hideWalletContent();
                }}
              >
                <WalletIcon />
                My Wallet
              </div>
              <div
                className={`nav-link ${
                  activeView === "transactions" ? "active" : ""
                }`}
                onClick={() => {
                  this.handleNavigation("transactions");
                }}
              >
                <Transactions />
                Transaction history
              </div>
              <div
                className={`nav-link ${
                  activeView === "profile" ? "active" : ""
                }`}
                onClick={() => {
                  this.handleNavigation("profile");
                }}
              >
                <Profile />
                <span>Profile</span>
              </div>
            </div>

            <div className="bottom">
              <div className="sign-out" onClick={() => this.props.customHook()}>
                <LogOut />
                Log out
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function SemiModalWithHook(Component) {
  return function WrappedComponent(props) {
    const customHook = () => {
      sessionStorage.removeItem("token");
      authService.keycloak.logout().then(() => {
        requestService.logout();
      });
    };
    return <Component {...props} customHook={customHook} />;
  };
}

export default SemiModalWithHook(inject("userStore", "walletStore", "styleStore")(observer(SemiModal)));
