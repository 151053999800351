/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import { observable, action, makeObservable, runInAction } from "mobx";
import { requestService } from "./../services";

export default class ExchangeStore {
  exchangeBills = {};
  exchangeCurrencyList = [];
  exchangeViewCurrency = null;
  exchangeId = null;

  constructor() {
    makeObservable(this, {
      exchangeBills: observable,
      exchangeCurrencyList: observable,
      exchangeViewCurrency: observable,
      exchangeId: observable,
      GetExchangeId: action,
      GetExchangeBills: action,
      GetExchangeCurrencyList: action,
      setExchangeCurrencyList: action,
      setExchangeViewCurrency: action,
      setExchangeBills: action,
      setExchangeID: action,
      decommissionBills: action,
    });
  }

  setExchangeViewCurrency(currency) {
    this.exchangeViewCurrency = currency || '';
  }

  setExchangeCurrencyList(list = []) {
    this.exchangeCurrencyList = list || [];
  }

  setExchangeBills(data) {
    this.exchangeBills = data;
  }

  setExchangeID(id) {
    this.exchangeId = id;
  }

  GetExchangeId() {
    requestService.getExchangeServiceInfo().then((data) => {
      if (data.exchangeServiceInfo && data.exchangeServiceInfo.length > 0) {
        const { bearerIdentifier } = data.exchangeServiceInfo[0];
        runInAction(() => {
          this.setExchangeID(bearerIdentifier);
        });
      }
    }).catch((error) => {
      console.error("Failed to fetch exchange service info:", error);
    });
  }

  GetExchangeCurrencyList() {
    return requestService.getExchangeCurrencyList().then(response => {
      const currencies = response.currencies || [];
      runInAction(() => {
        this.setExchangeCurrencyList(currencies);
      });
      return currencies;
    }).catch((error) => {
      console.error("Failed to fetch exchange currencies:", error);
      return [];
    });
  }

  GetExchangeBills(currency) {
    requestService.getExchangeBills(currency).then((data) => {
      runInAction(() => {
        this.setExchangeBills(data);
      });
    }).catch((error) => {
      console.error("Failed to fetch exchange bills:", error);
    });
  }

  decommissionBills(data) {
    return requestService.decommissionBills(data)
      .catch((error) => {
        console.error("Failed to decommission bills:", error);
        throw error;
      });
  }  
}