import React from 'react';

import TableRow from './TableRow';

function TableHeader({ children }) {
  return (
    <thead className="table-header">
      <TableRow>{children}</TableRow>
    </thead>
  );
}

export default TableHeader;
