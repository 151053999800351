/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import RequestService from './request';
import AuthService from './auth';
import WebSocketService from './websocket';
import keycloakService from './keycloak';

const BACKEND = process.env.REACT_APP_WALLET_API_URL
  ? process.env.REACT_APP_WALLET_API_URL
  : (window.location.port !== 8088 ? "http://localhost:8088" : '');

const EMISSION_UNIT_URL = process.env.REACT_APP_EMISSION_UNIT_API_URL
  ? process.env.REACT_APP_EMISSION_UNIT_API_URL
  : (window.location.port !== 8088 ? "http://localhost:8088" : '');

const EXCHANGE_MACHINE_URL = process.env.REACT_APP_EXM_API_URL
  ? process.env.REACT_APP_EXM_API_URL
  : "http://localhost:9087";

const DATA_HARVESTING_SERVICE_URL = process.env.REACT_APP_DHS_API_URL
  ? process.env.REACT_APP_DHS_API_URL
  : "http://localhost:8089";

const keycloak = keycloakService();
const authService = new AuthService(keycloak);
const requestService = new RequestService(
  BACKEND, 
  EMISSION_UNIT_URL, 
  EXCHANGE_MACHINE_URL, 
  DATA_HARVESTING_SERVICE_URL, 
  authService
);
const webSocketService = new WebSocketService(BACKEND, authService);

export {
  authService,
  requestService,
  webSocketService,
  keycloak,
};
