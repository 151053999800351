/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import Button from "./../../General/Button";
import {
  Table,
  TableContent,
  TableHeader,
  TableTitle,
} from "./../../General/Table/Table";
import WalletItems from "../../Wallet/WalletItems";

class EmittedBills extends Component {
  render() {
    const { isFullView, isMobile, emissionsStore } = this.props;
    const totalEmissions = emissionsStore.totalEmissions?.[emissionsStore.emissionViewCurrency];
    const totalEmissionsKeys = totalEmissions && Object.keys(totalEmissions);

    const billsSum = totalEmissionsKeys?.reduce(function (sum, itemKey) {
      return sum + totalEmissions?.[itemKey];
    }, 0);

    const emittedBills = totalEmissionsKeys?.map((itemKey) => {
      return (
        <WalletItems
          isExpandDisabled
          isTable={isFullView && !isMobile}
          billsSum={billsSum}
          label={itemKey}
          value={totalEmissions?.[itemKey] || 0}
          key={itemKey}
          currency={emissionsStore.emissionViewCurrency?.toUpperCase()}
        />
      );
    });

    return (
      <div className="emission-bills">
        <div className="banner banner--visible">
          <h1>Emitted bills</h1>

          <div className="wallet-items">
            {isFullView && !isMobile ? (
              <Table>
                <TableHeader>
                  <TableTitle>BILL</TableTitle>
                  <TableTitle></TableTitle>
                  <TableTitle>QUANTITY</TableTitle>
                  <TableTitle>AMOUNT</TableTitle>
                </TableHeader>
                <TableContent>{emittedBills}</TableContent>
              </Table>
            ) : (
              emittedBills
            )}
          </div>

          <Button
            cyber
            transparent
            label="See more"
            onClick={() => this.props.setActiveView("myWallet")}
          />
        </div>
      </div>
    );
  }
}

export default inject("emissionsStore")(observer(EmittedBills));
