/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component, createRef } from "react";
import { observer, inject } from "mobx-react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { useMediaQuery } from 'react-responsive';

import Dashboard from "../Dashboard";
import SemiModal from "../SemiModal";
import MobileMenu from "../MobileMenu";

class Wallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPayView: false,
      showGetView: false,
      isSemiModalOpen: false,
      activeView: null,
      userName: props.userStore.user.userName,
      hasRole: (role) => props.userStore.hasRole(role)
    };
    this.dashboardRef = createRef();
  }

  showAsideInfo = () => {
    this.props.styleStore.toggleVisibleView("settings");
    this.setState({
      showPayView: false,
      showGetView: false,
    });
  };

  setActiveView = (view) => {
    this.setState({
      activeView: view,
    });
  };

  toggleSemiModal = (state) => {
    this.setState({
      isSemiModalOpen: state,
    });
    document.body.classList.toggle("mobile-fixed");
  };

  logout = () => {
    this.props.onLogout();
  };

  render() {
    const { visibleView } = this.props.styleStore;

    return (
      <div
        className={`view-main ${visibleView} ${
          this.state.isSemiModalOpen && "semimodal-open"
        }`}
      >
        <MobileMenu
          isSemiModalOpen={this.state.isSemiModalOpen}
          toggleSemiModal={this.toggleSemiModal}
        />
        <SemiModal
          isSemiModalOpen={this.state.isSemiModalOpen}
          userName={this.state.userName}
          title={this.props.title}
          setActiveView={this.setActiveView}
          activeView={this.state.activeView}
          toggleSemiModal={this.toggleSemiModal}
          isMobile={this.props.isMobile}
          hasRole={this.state.hasRole}
        />

        <TransitionGroup className="dashboard-wrap">
          <CSSTransition
            nodeRef={this.dashboardRef}
            in={true}
            timeout={100}
            classNames="dashboard"
            unmountOnExit
            appear
          >
            <Dashboard
              innerRef={this.dashboardRef}
              isMobile={this.props.isMobile}
              activeView={this.state.activeView}
              setActiveView={this.setActiveView}
            />
          </CSSTransition>
        </TransitionGroup>
        <div className="overlay"></div>
      </div>
    );
  }
}

function WalletWithHook(Component) {
  return function WrappedComponent(props) {
    const isMobile = useMediaQuery({ query: '(max-width: 620px)' });

    return <Component {...props} isMobile={isMobile} />;
  };
}

export default WalletWithHook(inject("styleStore", "userStore", "walletStore")(observer(Wallet)));
