/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import numeral from "numeral";
import Select from "react-select";
import moment from "moment";
import Button from "./../General/Button";

class Header extends Component {
  render() {
    const { walletStore } = this.props;
    const walletData = {
      balance: 0,
      ...walletStore.walletInfo,
    };

    const balance =
      numeral(
        parseFloat(walletData.balanceList?.[walletStore.activeCurrency] || 0)
      ).format("0.00") +
      " " +
      walletStore?.activeCurrency?.toUpperCase();

    const walletsDesc = walletStore.walletsList;

    return (
      <div className="header">
        <div>
          <h3 className="pad__t-8">Wallet Balance</h3>
          <div className="txt--xl balance">{balance}</div>
        </div>
        <div className="header-right">
          {walletsDesc?.length >= 2 && (
            <div className="z-index-100 pad__b-16">
              {walletsDesc && walletData.payeeId && (
                <Select
                  defaultValue={{
                    label: walletData.description || walletData.payeeId,
                    value: walletData.payeeId,
                  }}
                  classNamePrefix="select"
                  menuPlacement="auto"
                  options={walletsDesc.map((data) => ({
                    value: data.id,
                    label: data.description || data.id,
                  }))}
                  onChange={(v) => {
                    this.props.walletStore.GetWalletData(v.value);
                    this.props.walletStore.GetWalletHistory(v.value);
                    this.props.walletStore.GetBillsList(v.value);
                    this.props.walletStore.GetBalanceHistory({
                      id: v.value,
                      form: {
                        dateFrom: moment()
                          .subtract(1, "years")
                          .format("YYYY-MM-DD"),
                        dateTo: moment().format("YYYY-MM-DD"),
                        currency: this.props.walletStore.activeCurrency,
                      },
                      isYearly: true,
                    });
                  }}
                />
              )}
            </div>
          )}
          <div className="buttons-group">
            {walletData.description !== "Removed Bills Wallet" && (
              <Button
                cyber
                label="PAY"
                onClick={() => this.props.togglePayViewVisibility()}
              />
            )}
            <Button
              cyber
              transparent
              label="GET"
              onClick={() => this.props.toggleGetViewVisibility()}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default inject("styleStore", "userStore", "walletStore")(observer(Header));
