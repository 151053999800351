/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import Logo from "./../styles/assets/logo_white.svg";
import { ReactComponent as Menu } from "./../styles/assets/menuIcons/menu.svg";
import { ReactComponent as Close } from "./../styles/assets/menuIcons/close.svg";

export default class MobileMenu extends Component {
  render() {
    return (
      <div className="mobile-menu">
        <div
          className="mobile-menu_btn"
          onClick={() =>{
            this.props.toggleSemiModal(!this.props.isSemiModalOpen)
          }}
        >
          {this.props.isSemiModalOpen ? <Close /> : <Menu />}

        </div>
        <div className="logo-wrap">
          {this.props.title || (
            <img className="logo" src={Logo} alt="KSI logo" />
          )}
        </div>
      </div>
    );
  }
}
