/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import moment from "moment";
import { observer, inject } from "mobx-react";
import numeral from "numeral";

import {
  Table,
  TableContent,
  TableData,
  TableHeader,
  TableRow,
  TableTitle,
} from "./../../General/Table/Table";

class EmissionHistory extends Component {
  render() {
    const { isFullView, isMobile, emissionsStore } = this.props;
    const history = emissionsStore.emissionHistory?.[emissionsStore.emissionViewCurrency];

    return (
      <div className="banner banner--visible wide">
        <h1>Emission history</h1>
        {history?.map((itemKey, idx) => (
          <div className="mar__b-32" key={itemKey.time + idx}>
            <h1 className="t-blue-gradient">
              {moment(itemKey.time).format(isMobile ? "ll" : "llll")}
            </h1>
            <Table>
              <TableHeader>
                <TableTitle colSpan={1}>DENOMINATION</TableTitle>
                {!isMobile && <TableTitle>AMOUNT</TableTitle>}
                <TableTitle>SERIAL RANGE</TableTitle>
                {!isMobile && <TableTitle>TOTAL</TableTitle>}
              </TableHeader>
              <TableContent>
                {itemKey.emitted.map((item, id) => {
                  const sum =
                    (item.lastSerialNumber - item.firstSerialNumber + 1) *
                    (item.denomination / 100);
                  return (
                    <TableRow key={item.firstSerialNumber + idx}>
                      <TableData key="denomination">
                        <div className="flex__col">
                          <div
                            className="t-blue-gradient t-16-b"
                            key={item.denomination + id}
                          >
                            {numeral(item.denomination / 100).format("0.00") +
                              " " +
                              emissionsStore.emissionViewCurrency?.toUpperCase()}
                          </div>
                        </div>
                      </TableData>
                      {!isMobile && (
                        <TableData key="amount" colSpan={isFullView ? 0 : 1}>
                          <div>
                            <span className="amount-item">
                              <div className="flex__col">
                                <div
                                  className="t-bold"
                                  key={item.firstSerialNumber}
                                >
                                  {item.lastSerialNumber -
                                    item.firstSerialNumber +
                                    1}
                                </div>
                              </div>
                            </span>
                          </div>
                        </TableData>
                      )}
                      <TableData key="range" colSpan={isFullView ? 0 : 1}>
                        <div>
                          <span className="amount-item">
                            <div className="flex__col">
                              <div
                                className="t-blue-gradient t-16-b"
                                key={item.lastSerialNumber}
                              >
                                {item.firstSerialNumber +
                                  " - " +
                                  item.lastSerialNumber}
                              </div>
                            </div>
                          </span>
                        </div>
                      </TableData>
                      {!isMobile && (
                        <TableData key="sum" colSpan={isFullView ? 0 : 1}>
                          <div>
                            <span className="amount-item">
                              <div className="flex__col">
                                <div className="t-bold" key={idx}>
                                  {numeral(sum).format("0.00") +
                                    " " +
                                    emissionsStore.emissionViewCurrency?.toUpperCase()}
                                </div>
                              </div>
                            </span>
                          </div>
                        </TableData>
                      )}
                    </TableRow>
                  );
                })}
              </TableContent>
            </Table>
          </div>
        ))}
      </div>
    );
  }
}

export default inject("emissionsStore")(observer(EmissionHistory));
