/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import classNames from "classnames";
import QRCode from "qrcode";
import { observer, inject } from "mobx-react";
import { TextInput, Textarea, Toggler } from "./../General";
import { If, Then, Else } from "react-if";
import Button from "./../General/Button";

class ViewGet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
      showLoading: true,
      activeGet: "qr",
      publicKey: this.props.walletStore.walletInfo.payeeId,
      showName: !this.props.walletStore.walletInfo.anonymous,
      currency: this.props.walletStore.activeCurrency
    };
  }

  generateQrCode() {
    /*this.setState({
      showLoading: true,
    });*/
    setTimeout(() => {
      var canvas = document.getElementById("get-payment");
      let data = {
        payeeId: this.props.walletStore.walletInfo.payeeId,
        amount: this.state.amount,
        currency: this.state.currency,
      };

      QRCode.toCanvas(
        canvas,
        JSON.stringify(data),
        { errorCorrectionLevel: "H", scale: 12 },
        (error) => {
          if (error) console.error(error);
          this.setState({
            showLoading: false,
          });
        }
      );
    }, 1000);
  }

  async sharePublicKey(code) {
    window.location.href = `mailto:?body=Send ${this.state.amount} to ${this.props.userStore.user.userName} with Wallet ID: ${code}`;
  }

  onChange(field) {
    this.setState({
      ...field,
    });
    if (field.amount) {
      this.generateQrCode();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        this.generateQrCode();
      }
    }
  }

  updateActiveTab(value) {
    if (this.state.activeGet === "ask" && value === "qr") {
      this.generateQrCode();
    } else {
      this.setState((state, props) => ({
        publicKey: props.walletStore.walletInfo.payeeId,
      }));
    }
    this.setState({
      activeGet: value,
    });
  }

  render() {
    let viewGetClasses = classNames({
      "view-get": true,
      "is--visible": this.props.visible,
    });

    let loadingClasses = classNames({
      loading: true,
      hidden: !this.state.showLoading,
    });

    /*const tabs = [
      {
        label: "QR Code",
        value: "qr",
      },
      {
        label: "Ask",
        value: "ask",
      },
    ];*/

    return (
      <div className={viewGetClasses}>
        <div className="view-header">
          <div className="close-btn" onClick={() => this.props.hideGetView()}>
            Close X
          </div>
          <div className="title">Receive payment</div>
        </div>
        <div className="view-content">
          <div className="view-content-wrapper">
            <If condition={this.state.activeGet === "qr"}>
              <Then>
                <div className="canvas-wrapper">
                  <div className={loadingClasses}>Please enter amount to generate QR code</div>
                  <canvas id="get-payment" />
                </div>
                <TextInput
                  type="number"
                  inputType={"number"}
                  label={"Amount"}
                  onChange={(value) => this.onChange({ amount: value })}
                  value={this.state.amount}
                  classNames={"inline"}
                />
              </Then>
              <Else>
                <Textarea
                  label={"Receiver's Wallet ID"}
                  onChange={(value) => this.onChange({ publicKey: value })}
                  value={this.state.publicKey}
                  classNames={"inline"}
                />
                <TextInput
                  type="number"
                  inputType={"number"}
                  label={"Amount"}
                  onChange={(value) => this.onChange({ amount: value })}
                  value={this.state.amount}
                  classNames={"inline"}
                />
                <Toggler
                  label={"Add my ID"}
                  value={this.state.showName}
                  onChange={() =>
                    this.onChange({ showName: !this.state.showName })
                  }
                />
                <Button
                  cyber
                  transparent
                  className="scan-btn"
                  label="Send"
                  onClick={() =>
                    this.sharePublicKey(
                      this.props.walletStore.walletInfo.payeeId
                    )
                  }
                />
              </Else>
            </If>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("userStore", "walletStore")(observer(ViewGet));
