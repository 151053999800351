import React from 'react';
import classNames from 'classnames';

function TableTitle({ children, colSpan, className }) {
  return (
    <th className={classNames('table-title', className)} style={{ flex: colSpan }}>
      <span>{children}</span>
    </th>
  );
}

export default TableTitle;
