/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import { observable, action, makeObservable } from 'mobx';

export default class AlertsStore {
  alert = {
    message: {}
  };
  timeout = null;

  constructor() {
    makeObservable(this, {
      alert: observable,
      setSuccess: action,
      setError: action
    });
  }

  setSuccess(message) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    action(() => {
      this.alert = {
        type: 'success',
        message
      };
    })();

    this.timeout = setTimeout(
      action(() => {
        this.alert = {
          message: {}
        };
      }), 3000);
  }
  setError(message) {
    let errorMessage = {
      title: message.title || "Error",
      body: message.body || ""
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    action(() => {
      this.alert = {
        type: 'error',
        message: errorMessage
      };
    })();

    this.timeout = setTimeout(
      action(() => {
        this.alert = {
          message: {}
        };
      }), 3000);
  }
}
