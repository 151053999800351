/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component, createRef } from "react";
import { observer, inject } from "mobx-react";
import classNames from "classnames";
import numeral from "numeral";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { TableData, TableRow } from "./../General/Table/Table";

class WalletItems extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      serialsList: [],
    };
    this.percentageBarRef = createRef();
    this.itemInfoRef = createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.styleStore.subscribe(() => {
      this._isMounted &&
        this.setState(() => ({
          expanded: false,
        }));
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.styleStore.hideWalletContent();
  }

  updateSerialsList = (serials) => {
    this.setState({ serialsList: serials });
  };

  toggleItem() {
    if (this.state.expanded) {
      this.props.styleStore.hideWalletContent();
    } else {
      this.props.styleStore.showWalletContent();
      this.requestBillSerials();
    }

    this.setState((state) => ({
      expanded: !state.expanded,
    }));
  }

  requestBillSerials() {
    const { label, walletStore } = this.props;
    walletStore.GetBillDenominationSerials(
      label,
      walletStore.activeCurrency,
      50,
      0,
      this.updateSerialsList
    );
  }

  render() {
    let walletItemClasses = classNames({
      "wallet-item": true,
      "is--not-active": this.props.styleStore.walletContentToggled,
      "is--expanded": this.state.expanded,
      "is--table": this.props.isTable,
    });

    const listItems = this.state.serialsList.map((serial, index) => {
      return (
        <p key={index + serial}>{serial}</p>
      );
    });

    const billsPercentage =
      parseFloat((this.props.value * 100) / this.props.billsSum).toFixed(2) +
      "%";
    
    const currency = this.props.currency || this.props.walletStore.activeCurrency?.toUpperCase();

    return (
      <>
        {this.props.isTable ? (
          <>
            <TableRow
              className={`row-top ${walletItemClasses}`}
              onClick={() => !this.props.isExpandDisabled && this.toggleItem()}
            >
              <TableData>
                <div>{numeral(this.props.label / 100).format("0.00")}</div>
              </TableData>
              <TableData>
                <div className="see-all">
                  {this.state.expanded ? "Close" : "See All Serials"}
                </div>
              </TableData>
              <TableData>
                <div>
                  {this.props.value} / {billsPercentage}
                </div>
              </TableData>
              <TableData>
                <div className="total">
                  {numeral(this.props.value * (this.props.label / 100)).format(
                    "0.00"
                  ) +
                    " " +
                    currency}
                </div>
              </TableData>
            </TableRow>
            <TableRow
              className={`row-bottom ${walletItemClasses}`}
              onClick={() => !this.props.isExpandDisabled && this.toggleItem()}
            >
              <TableData>
                <TransitionGroup>
                  <CSSTransition
                    nodeRef={this.percentageBarRef}
                    in={true}
                    timeout={100}
                    classNames="percentage-bar"
                    unmountOnExit
                    appear
                  >
                    <div ref={this.percentageBarRef} className="percentage-bar">
                      <span
                        style={{
                          width: billsPercentage,
                        }}
                      ></span>
                    </div>
                  </CSSTransition>
                </TransitionGroup>
                <div className="item-info">{listItems}</div>
              </TableData>
            </TableRow>
          </>
        ) : (
          <div
            className={walletItemClasses}
            onClick={() => !this.props.isExpandDisabled && this.toggleItem()}
          >
            <div className="item-header">
              <div className="label">
                {numeral(this.props.label / 100).format("0.00")}
              </div>
              <div className="count">
                {this.props.value} ({billsPercentage})
              </div>
              <div className="total">
                {numeral(this.props.value * (this.props.label / 100)).format(
                  "0.00"
                ) +
                  " " +
                  currency}
              </div>
            </div>
            <TransitionGroup>
              <CSSTransition
                nodeRef={this.itemInfoRef}
                in={true}
                timeout={100}
                classNames="percentage-bar"
                unmountOnExit
                appear
              >
                <div ref={this.itemInfoRef} className="percentage-bar">
                  <span
                    style={{
                      width: billsPercentage,
                    }}
                  ></span>
                </div>
              </CSSTransition>
            </TransitionGroup>
            <div className="item-info">{listItems}</div>
          </div>
        )}
      </>
    );
  }
}

export default inject("styleStore", "walletStore")(observer(WalletItems));
