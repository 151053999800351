/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { observer, inject } from "mobx-react";
import Select from "react-select";

import Button from "./../../General/Button";
import { Form, FormContent, FormFooter } from "../../Form/Form";
import { extractFormikError } from "./../../Form/utils/utils";
import Textfield from "../../TextField/TextField";
import InputSelect from "../../InputSelect/InputSelect";
import { TextInput } from "./../../General";

function isHex(h) {
  if (!h) {return false}
  return Boolean(h.match(/^([0-9A-Fa-f])+$/i));
}

class AddEmission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nrOfBills: 2,
    };
  }

  render() {
    const { emissionsStore, alertsStore, walletStore, userStore, exchangeStore } = this.props;
    let emissionsArr = [];

    Object.keys(emissionsStore.emissionHistory)?.map((key) => {
      return emissionsStore.emissionHistory?.[key]?.map((itemKey) => {
        return itemKey.emitted?.map((item) => {
          return emissionsArr.push(item.lastSerialNumber);
        });
      });
    });

    const lastSerialNumberInUse = Math.max(...emissionsArr);
    const totalEmissionsKeys = Object.keys(emissionsStore.totalEmissions);

    let validationSchema = {};

    for (var x = 0; x < this.state.nrOfBills; x++) {
      validationSchema[x + "_denomination"] = Yup.number().min(0.01, 'Min value 0.01')
        .required("Denomination is required.");
      validationSchema[x + "_amount"] = Yup.number().min(1, 'Min value 1')
        .required("Amount is required.")
    }
    validationSchema["bearer"] = Yup.string()
        .required("Bearer identifier is required.")
        .test('hex', "Bearer identifier must be valid hexadecimal", function(value) {
          return isHex(value); // validateBase64(value);
        });

    const billsArr = (values) => {
      const denominationsSize = Object.keys(values).length / 2;
      let billsArr = [];
      let changedRange = 0;

      Array.from({ length: denominationsSize }, (_, i) => {
        const amount = +values[i + "_amount"];
        const denomVal = Number(values[i + "_denomination"]);
        // Input value needs to be multiplied because 1 = 0.01
        // Option value is all ready calculated based on this logic
        const multiplier = !walletStore.currencyList.includes(
          emissionsStore.emissionViewCurrency
        ) ||
        !emissionsStore.totalEmissions?.[
          emissionsStore.emissionViewCurrency
        ]?.[denomVal] ? 100 : 1

        billsArr.push({
          denomination: denomVal * multiplier,
          firstSerialNumber:
            changedRange < lastSerialNumberInUse
              ? lastSerialNumberInUse + 1
              : changedRange + 1,
          lastSerialNumber:
            changedRange < lastSerialNumberInUse
              ? lastSerialNumberInUse + amount
              : changedRange + 1 + amount - 1,
        });

        changedRange =
          changedRange < lastSerialNumberInUse
            ? lastSerialNumberInUse + amount
            : changedRange + amount;

        return billsArr;
      });

      return billsArr;
    };

    let options = walletStore.currencyList.map((currency) => {
      return {
        value: currency,
        label: currency.toUpperCase(),
      };
    });

    return (
      <>
        <div className="emissions">
          <div className="banner banner--visible flex__row">
            <div className="w-50">
              <h1>SELECT CURRENCY</h1>
              {walletStore.currencyList.length > 1 &&
              <Select
                  classNamePrefix="select"
                  menuPlacement="auto"
                  options={options}
                  onChange={(v) => {
                    emissionsStore.setEmissionViewCurrency(v.value);
                  }}
                  defaultValue={options[0]}
              />}

            </div>
            <div className="border-separator"></div>
            <div className="w-50 flex__col">
              <h1>ADDED CURRENCIES</h1>
              {totalEmissionsKeys.map((key, idx) => {
                const keyNameFallback = key ? key : "KSI";
                return (
                  <h3 key={key} className="t-orange-gradient">
                    {idx + 1 + ". " + keyNameFallback.toUpperCase()}
                    {key === emissionsStore.emissionViewCurrency &&
                      " - Selected For Emissions"}
                  </h3>
                );
              })}
              <div className="t-14 mar__t-auto pad__t-16">
                (You can change the currency of the wallet for transactions &
                balance under{" "}
                <span
                  className="t-link"
                  onClick={() => this.props.setActiveView("profile")}
                >
                  Profile
                </span>
                )
              </div>
            </div>
          </div>
          <div className="mar__t-32"></div>
          <div className="banner banner--visible">
            <h1>ADD EMISSION</h1>
            <TextInput
              inputType="number"
              min="1"
              disabled={
                emissionsStore.emissionViewCurrency !== "" &&
                !emissionsStore.emissionViewCurrency
              }
              label={"Select the amount of denominations"}
              onChange={(value) =>
                this.setState({
                  nrOfBills: value,
                })
              }
              value={this.state.nrOfBills}
              classNames={"emissions__form-top"}
            />
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              validationSchema={
                !walletStore.currencyList.includes(
                  emissionsStore.emissionViewCurrency
                )
                  ? Yup.object().shape({})
                  : Yup.object().shape(validationSchema)
              }
              initialValues={{}}
              onSubmit={async (values, { resetForm }) => {

                const emissionData = {
                  currency: emissionsStore.emissionViewCurrency,
                  bills: billsArr(values),
                  bearer: values.bearer
                };

                if (
                  !walletStore.currencyList.includes(
                    emissionsStore.emissionViewCurrency
                  )
                ) {
                  this.props.emissionsStore.setIsAddCurrencyLoading(true);

                  try {
                    await this.props.emissionsStore.AddCurrency(emissionData);
                    this.props.emissionsStore.setIsAddCurrencyLoading(false);
                    alertsStore.setSuccess({
                      title: "New currency added",
                    });
                  } catch (err) {
                    this.props.emissionsStore.setIsAddEmissionLoading(false);
                    alertsStore.setError({
                      title: "Adding currency failed",
                    });
                  }
                } else {
                  this.props.emissionsStore.setIsAddEmissionLoading(true);

                  this.props.emissionsStore.AddEmissions(emissionData).then(() => {
                    this.props.emissionsStore.setIsAddEmissionLoading(false);
                    this.props.emissionsStore.GetEmissionsHistory();
                    alertsStore.setSuccess({
                      title: "New emission",
                    });
                  }).catch(err => {
                    this.props.emissionsStore.setIsAddEmissionLoading(false);
                    alertsStore.setError({
                      title: "Emission failed: " + err,
                    });
                    this.props.emissionsStore.GetEmissionsHistory();
                  });
                }

                resetForm();
              }}
            >
              {(formikProps) => {
                const { handleSubmit, errors, touched, values } = formikProps;
                const totalEmissions =
                  emissionsStore.totalEmissions?.[
                    emissionsStore.emissionViewCurrency
                  ];
                const totalEmissionsKeys =
                  totalEmissions && Object.keys(totalEmissions);

                return (
                  <form onSubmit={handleSubmit}>
                    <Form>
                      <FormContent>
                        {Array.from(
                          { length: this.state.nrOfBills },
                          (_, i) => {
                            const denomVal = values[i + "_denomination"];

                            return (
                              <div key={i + "_denomination"}>
                                <div className="emissions__form-title-wrap">
                                  <div className="emissions__form-title">
                                    {i + 1 + ". Bill"}
                                  </div>
                                  <div className="emissions__range">
                                    {billsArr(values) && billsArr(values)[i]
                                      ? "(" +
                                        billsArr(values)[i]?.firstSerialNumber +
                                        " - " +
                                        billsArr(values)[i]?.lastSerialNumber +
                                        ")"
                                      : ""}
                                  </div>
                                </div>

                                <div className="emissions__form-inputs">
                                  <InputSelect
                                    label="Denomination"
                                    id={i + "_denomination"}
                                    name={i + "_denomination"}
                                    min={0.01}
                                    options={totalEmissionsKeys?.map(
                                      (itemKey) => ({
                                        value: itemKey,
                                        label: itemKey / 100,
                                      })
                                    )}
                                    inputType="number"
                                    className="emissions__form-input"
                                    placeholder="Change Denomination"
                                    error={extractFormikError(errors, touched, [
                                      i + "_denomination",
                                    ])}
                                    disabled={
                                      emissionsStore.emissionViewCurrency !== "" &&
                                      !emissionsStore.emissionViewCurrency
                                    }
                                  />
                                  {!walletStore.currencyList.includes(
                                    emissionsStore.emissionViewCurrency
                                  ) ||
                                  !emissionsStore.totalEmissions?.[
                                    emissionsStore.emissionViewCurrency
                                  ]?.[denomVal] ? (
                                    <Textfield
                                      id={i + "_amount"}
                                      name={i + "_amount"}
                                      label={
                                        (emissionsStore.emissionViewCurrency !==
                                          "" &&
                                          !emissionsStore.emissionViewCurrency) ||
                                        !denomVal
                                          ? "Amount"
                                          : "Amount"
                                      }
                                      type="number"
                                      className="emissions__form-input"
                                      placeholder="Change Amount"
                                      disabled={
                                        emissionsStore.emissionViewCurrency !==
                                          "" &&
                                        !emissionsStore.emissionViewCurrency
                                      }
                                      error={extractFormikError(
                                        errors,
                                        touched,
                                        [i + "_amount"]
                                      )}
                                    />
                                  ) : (
                                    <Textfield
                                      id={i + "_amount"}
                                      name={i + "_amount"}
                                      label="Amount"
                                      type="number"
                                      className="emissions__form-input"
                                      placeholder="Change Amount"
                                      disabled={
                                        emissionsStore.emissionViewCurrency !==
                                          "" &&
                                        !emissionsStore.emissionViewCurrency
                                      }
                                      error={extractFormikError(
                                        errors,
                                        touched,
                                        [i + "_amount"]
                                      )}
                                      min={1}
                                    />
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                        <InputSelect
                          label={"Bearer identifier"}
                          id={"bearer"}
                          name={"bearer"}
                          error={extractFormikError(
                            errors,
                            touched,
                            ["bearer"]
                          )}
                          className={"emissions__form-bottom"}
                          options={[
                            userStore?.user?.walletID ? { value: userStore.user.walletID, label: `Wallet: ${userStore.user.walletID}` } : null,
                            exchangeStore?.exchangeId ? { value: exchangeStore.exchangeId, label: `Exchange machine: ${exchangeStore.exchangeId}` } : null,
                          ].filter(Boolean)}
                        />

                      </FormContent>
                      <FormFooter>
                        <Button
                          cyber
                          label={
                            !walletStore.currencyList.includes(
                              emissionsStore.emissionViewCurrency
                            )
                              ? "Add currency"
                              : "Emit"
                          }
                          type="submit"
                          className="form-submit pad__t-16"
                          working={
                            this.props.emissionsStore.isAddEmissionLoading ||
                            this.props.emissionsStore.isAddCurrencyLoading
                          }
                          disabled={
                            (emissionsStore.emissionViewCurrency !== "" &&
                            !emissionsStore.emissionViewCurrency) ||
                            this.props.emissionsStore.isAddEmissionLoading
                          }
                        />
                      </FormFooter>
                    </Form>
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
      </>
    );
  }
}

export default inject("alertsStore", "emissionsStore", "walletStore", "exchangeStore", "userStore")(observer(AddEmission));
