/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import Keycloak from 'keycloak-js';

let keycloakInstance;

const keycloakService = () => {
  if (!keycloakInstance) {
    const issuerUrl = process.env.REACT_APP_OPEN_ID_ISSUER_URL;
    const [url, realm] = issuerUrl.split('/realms/');

    keycloakInstance = new Keycloak({
      url: url,
      realm: realm,
      clientId: process.env.REACT_APP_OPEN_ID_CLIENT_ID,
    });

    keycloakInstance.onTokenExpired = () => {
      keycloakInstance.updateToken(5).catch(() => {
        console.error('Failed to refresh token');
      });
    };
  }
  return keycloakInstance;
};

export default keycloakService;
