/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import { observable, action, makeObservable } from 'mobx';
import { requestService, authService } from './../services';

export default class UserStore {
  user = {};
  loading = true;

  constructor() {
    makeObservable(this, {
      user: observable,
      loading: observable,
      setUser: action,
      removeUser: action,
      GetUserData: action,
      hasRole: action,
      setLoading: action,
    });
  }

  setUser(user) {
    this.user = {
      ...user,
      userName: `${user.firstName} ${user.lastName}`,
      roles: authService.extractRoles(authService.getToken())
    };
    this.loading = false;
  }

  setLoading(loading) {
    this.loading = loading;
  }

  removeUser() {
    this.user = {};
    this.loading = false;
  }

  async GetUserData() {
    this.setLoading(true);
    try {
      const data = await requestService.getUserData();
      this.setUser(data);
      return data;
    } catch (error) {
      this.setLoading(false);
      throw error;
    }
  }

  hasRole(role) {
    return this.user.roles && this.user.roles.includes(role);
  }
}
