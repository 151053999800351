/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

export default class WebSocketService {
  constructor(apiUrl, authService) {
    this.apiUrl = apiUrl.split("://")[1];
    this.authService = authService;
    this.opened = false;
    this.ws = null;
    this.wsProtocol = apiUrl.startsWith("https") ? "wss" : "ws";
    this.walletReadyCallback = null;
  }

  connect(walletId, alertStore, walletStore, emissionsStore) {
    if (!this.opened) {
      this.ws = new WebSocket(
        `${this.wsProtocol}://${this.apiUrl}/ws/v1/wallet`,
        this.authService.getToken()
      );

      this.ws.onopen = (event) => {
        this.opened = true;
      };
      this.ws.onerror = (event) => {
        console.log('WebSocket error:', event);
      };
      this.ws.onclose = (event) => {
        console.log('WebSocket closed:', event);
        this.opened = false;
      };
      this.ws.onmessage = (event) => {
        let data = JSON.parse(event.data);
        this.handleWebSocketMessage(data, walletStore, alertStore);
      };
    }
  }

  handleWebSocketMessage(data, walletStore, alertStore) {
    const { method, type, body } = data;

    if (type === "WalletState" && body.ready) {
      if (this.walletReadyCallback) {
        this.walletReadyCallback();
      }
    } else if (method === "Notification" && type === "Payment") {
      this.handlePaymentNotification(body, walletStore, alertStore);
    } else {
      walletStore.handleWebSocketMessage(data, alertStore);
    }
  }

  handlePaymentNotification(body, walletStore, alertStore) {
    walletStore.handlePaymentNotification(body, alertStore);
  }

  onWalletReady(callback) {
    this.walletReadyCallback = callback;
  }

  sendRequest(request) {
    if (this.ws && this.opened) {
      this.ws.send(JSON.stringify(request));
    } else {
      console.log("WebSocket is not open");
    }
  }

  close() {
    if (this.ws) {
      this.ws.close();
    }
  }
}
