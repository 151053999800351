import React from "react";

import classNames from "classnames";

import { useField, useFormikContext } from "formik";

export default function Textfield(props) {
  const {
    hiddenPassword,
    error,
    transparent,
    floatingFixedPoint,
    isNumberFloat,
    ...inputProps
  } = props;
  const { setFieldValue, handleBlur } = useFormikContext();
  const [field] = useField(props);

  const className = classNames(
    "textfield",
    {
      "textfield--hidden-password": hiddenPassword,
      "textfield--error": error,
      "textfield--transparent": transparent,
    },
    props.className
  );

  const handleChange = (e) => {
    const regexNumber = /^[+-]?\d*(?:[.,]\d*)?$/;
    const fixedPoint = floatingFixedPoint || "2";
    const regexFloatString = "^\\d+(\\.\\d{0," + fixedPoint + "})?$";
    const regexFloat = new RegExp(regexFloatString);

    if (
      isNumberFloat &&
      e.target.value !== "" &&
      (!regexNumber.test(e.target.value) || !regexFloat.test(e.target.value))
    ) {
      return false;
    }

    props.onChange?.(e, field.name);
    setFieldValue(field.name, e.target.value);
  };

  return (
    <div className={className} data-testid="textfield">
      <div className="textfield__inner">
        {props.label && (
          <label className="textfield__label">{props.label}</label>
        )}
        <input
          {...inputProps}
          onChange={handleChange}
          onBlur={handleBlur}
          value={
            (props.type === "number"
              ? Math.max(0, field.value)
              : field.value) || ""
          }
          className="textfield__input"
          onKeyDown={
            props.type === "number"
              ? (evt) =>
                  ["e", "E", "+", "-", ",", "."].includes(evt.key) &&
                  evt.preventDefault()
              : undefined
          }
          min={
            props.type === "number" ? (props.min ? props.min : 0) : undefined
          }
          step={props.type === "number" ? props.step : undefined}
        />
      </div>
      {error && error.length > 0 && (
        <span data-testid="textfield__error" className="textfield__error">
          {error}
        </span>
      )}
    </div>
  );
}
