/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import WalletItems from "./WalletItems";
import {
  Table,
  TableContent,
  TableHeader,
  TableTitle,
} from "./../General/Table/Table";

class WalletContent extends Component {
  render() {
    const { walletStore, isFullView, isMobile } = this.props;
    const billsSum = walletStore.billsList
      .reduce((total, bill) => bill.count + total, 0);

    const listItems = walletStore.billsList
      .map((number) => {
        const { denomination, count } = number;

        return (
          <WalletItems
            isTable={isFullView && !isMobile}
            billsSum={billsSum}
            label={denomination}
            value={count}
            key={denomination}
            currency={walletStore.activeCurrency?.toUpperCase()}
          />
        );
      });

    return (
      <div className="wallet-items">
        {isFullView && !isMobile ? (
          <Table>
            <TableHeader>
              <TableTitle>BILL</TableTitle>
              <TableTitle></TableTitle>
              <TableTitle>QUANTITY</TableTitle>
              <TableTitle>AMOUNT</TableTitle>
            </TableHeader>
            <TableContent>{listItems}</TableContent>
          </Table>
        ) : (
          listItems
        )}
      </div>
    );
  }
}

export default inject("walletStore")(observer(WalletContent));
