import { getIn } from "formik";

export const extractFormikError = (errors, _touched, names) =>
  names
    .map((name) => {
      const error = getIn(errors, name);
      if (!error || typeof error !== "string") {
        return "";
      }

      return error ? error : "";
    })
    .find((error) => !!error) || "";
