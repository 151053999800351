/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import axios from 'axios';

export default class RequestService {
  constructor(apiUrl, emissionUnitUrl, exchangeMachineUrl, dhsUrl, authService) {
    this.apiUrl = apiUrl;
    this.emissionUnitUrl = emissionUnitUrl;
    this.exchangeMachineUrl = exchangeMachineUrl;
    this.dhsUrl = dhsUrl;
    this.authService = authService;

    this.request = axios.create({
      baseURL: apiUrl,
      maxRedirects: 0,
      headers: {
        Accept: "application/json"
      }
    });

    this.request.interceptors.request.use(async (config) => {
      const token = this.authService.getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });

    this.request.interceptors.response.use((response) => {
      if (response.data && response.data.redirectUrl) {
        window.location = response.data.redirectUrl;
      }
      return response.data;
    }, async (error) => {
      if (error.response.status === 401) {
        try {
          await this.authService.keycloak.updateToken(5);
          const token = this.authService.getToken();
          error.config.headers['Authorization'] = `Bearer ${token}`;
          return this.request(error.config);
        } catch (refreshError) {
          sessionStorage.clear();
          window.location.href = "/";
          return Promise.reject(refreshError);
        }
      }
      if (error.response.status === 403) {
        sessionStorage.clear();
        window.location.href = "/";
        return Promise.reject();
      }
      let errorMessage = error.response ? error.response.data : error;
      return Promise.reject(errorMessage);
    });
  }

  fetch(url, method = "GET", data = {}) {
    return this.request({
      method,
      url,
      data
    });
  }

  logout() {
    this.authService.removeAccessToken();
    this.authService.keycloak.logout();
  }

  getUserData() {
    let url = `${this.apiUrl}/api/v1/profile`;
    return this.fetch(url);
  }

  getCurrencies() {
    let url = `${this.apiUrl}/api/v1/currencies`;
    return this.fetch(url);
  }

  getEmissionsHistory() {
    let url = `${this.emissionUnitUrl}/api/emissions`;
    return this.fetch(url);
  }

  getEmissionsGatewaysInfo() {
    let url = `${this.emissionUnitUrl}/api/deployed-shards`
    return this.fetch(url);
  }

  addEmissions(data) {
    let url = `${this.emissionUnitUrl}/api/emissions`;
    return this.fetch(url, "POST", data);
  }

  getExchangeCurrencyList() {
    let url = `${this.exchangeMachineUrl}/currency/list`;
    return this.fetch(url);
  }

  getExchangeBills(currency) {
    let url = `${this.exchangeMachineUrl}/bills?currency=${currency}`;
    return this.fetch(url);
  }

  getExchangeServiceInfo() {
    let url = `${this.apiUrl}/api/v1/exchange-service-info`;
    return this.fetch(url);
  }

  decommissionBills(data) {
    let url = `${this.exchangeMachineUrl}/decommission`;
    return this.fetch(url, "POST", data);
  }

  getDHSEmissionsData() {
    let url = `${this.dhsUrl}/api/emissions`;
    return this.fetch(url);
  }
}
