/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

export default class AuthService {
  constructor(keycloakService) {
    this.keycloak = keycloakService;
    this.accessToken = null;
    this.user = null;
  }

  setAccessToken(token) {
    this.accessToken = token;
    sessionStorage.setItem("token", token);
  }

  removeAccessToken() {
    this.accessToken = null;
    sessionStorage.removeItem("token");
  }

  setUser(user) {
    this.user = user;
  }

  getUser() {
    return this.user;
  }

  getToken() {
    return this.accessToken || this.keycloak.token;
  }

  extractRoles(token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      let roles = [];

      if (payload.realm_access && payload.realm_access.roles) {
        roles = payload.realm_access.roles;
      }

      if (roles.length === 0 && payload.resource_access) {
        const audience = payload.aud || [];
        audience.forEach(aud => {
          if (payload.resource_access[aud] && payload.resource_access[aud].roles) {
            roles = roles.concat(payload.resource_access[aud].roles);
          }
        });
      }

      return roles;
    } catch (error) {
      console.error('Error extracting roles from token:', error);
      return [];
    }
  }

  hasRole(role) {
    const roles = this.extractRoles(this.getToken());
    return roles.includes(role);
  }
}
