/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import { observable, action, makeObservable, runInAction } from "mobx";
import { requestService } from "./../services";

const ADD_EMISSION_POLL_RETRY_COUNT = 10;
const ADD_EMISSION_POLL_RETRY_DELAY_MS = 2000;

export default class EmissionsStore {
  emissionHistory = {};
  totalEmissions = {};
  dhsEmissions = {};
  gatewaysInfo = {};
  metricsCurrencyList = [];
  metricsViewCurrency = null;
  isAddEmissionLoading = false;
  isDHSEmissionsLoading = false;
  emissionViewCurrency = null;
  isAddCurrencyLoading = false;

  constructor() {
    makeObservable(this, {
      emissionHistory: observable,
      totalEmissions: observable,
      dhsEmissions: observable,
      gatewaysInfo: observable,
      metricsCurrencyList: observable,
      metricsViewCurrency: observable,
      isAddEmissionLoading: observable,
      isDHSEmissionsLoading: observable,
      emissionViewCurrency: observable,
      isAddCurrencyLoading: observable,
      AddCurrency: action,
      setIsAddCurrencyLoading: action,
      setEmissionViewCurrency: action,
      setIsAddEmissionLoading: action,
      setDHSEmissions: action,
      setEmissionsGatewaysInfo: action,
      setMetricsCurrencyList: action,
      setMetricsViewCurrency: action,
      GetEmissionsHistory: action,
      GetDHSEmissions: action,
      GetEmissionsGatewaysInfo: action,
      AddEmissions: action,
      setIsDHSEmissionsLoading: action,
    });
  }

  setIsAddEmissionLoading(isLoading) {
    this.isAddEmissionLoading = isLoading || false;
  }

  setIsDHSEmissionsLoading(isLoading) {
    this.isDHSEmissionsLoading = isLoading || false;
  }

  setEmissionViewCurrency(currency) {
    this.emissionViewCurrency = currency || '';
  }

  setDHSEmissions(emissions) {
    this.dhsEmissions = emissions;
    const currencies = Array.from(new Set(Object.values(emissions).map(item => item.currency.toLowerCase())));
    this.setMetricsCurrencyList(currencies);
    this.setMetricsViewCurrency(currencies[0]);
  }

  setEmissionsGatewaysInfo(data) {
    this.gatewaysInfo = data;
  }

  setMetricsCurrencyList(currencies) {
    this.metricsCurrencyList = Array.isArray(currencies) ? currencies : [];
  }

  setMetricsViewCurrency(currency) {
    this.metricsViewCurrency = currency;
  }

  AddCurrency(data) {
    let url = `/api/add-currency`;
    return requestService.fetchRequest(url, "POST", data);
  }

  setIsAddCurrencyLoading(isLoading) {
    this.isAddCurrencyLoading = isLoading || false;
  }

  GetEmissionsHistory() {
    requestService.getEmissionsHistory().then((data) => {
      const emissions = JSON.parse(JSON.stringify(data));
      let emissionsUpperCase = {};
      Object.keys(emissions).forEach(key => {
        emissionsUpperCase[key.toUpperCase()] = emissions[key];
      });

      Object.keys(emissionsUpperCase).forEach(currency => {
        emissionsUpperCase[currency] = emissionsUpperCase[currency].sort((a, b) => {
          return (new Date(a.time)) > (new Date(b.time)) ? -1 : 1;
        });
      });

      runInAction(() => {
        this.emissionHistory = emissionsUpperCase;

        const totalEmissions = {};
        Object.keys(this.emissionHistory).forEach(currency => {
          if (!totalEmissions[currency]) {
            totalEmissions[currency] = {};
          }
          this.emissionHistory[currency].forEach(history => {
            history.emitted.forEach(emission => {
              const billsEmitted = emission.lastSerialNumber - emission.firstSerialNumber + 1;
              if (!totalEmissions[currency][emission.denomination]) {
                totalEmissions[currency][emission.denomination] = billsEmitted;
              } else {
                totalEmissions[currency][emission.denomination] = totalEmissions[currency][emission.denomination] + billsEmitted;
              }
            });
          });
        });

        this.totalEmissions = totalEmissions;
      });
    });
  }

  GetDHSEmissions() {
    this.setIsDHSEmissionsLoading(true);
    requestService.getDHSEmissionsData().then((data) => {
      runInAction(() => {
        this.setDHSEmissions(data);
        this.setIsDHSEmissionsLoading(false);
      });
    }).catch((error) => {
      console.error("Error fetching DHS emissions data:", error);
      runInAction(() => {
        this.setDHSEmissions({});
        this.setIsDHSEmissionsLoading(false);
      });
    });
  }

  GetEmissionsGatewaysInfo() {
    requestService.getEmissionsGatewaysInfo().then((data) => {
      this.setEmissionsGatewaysInfo(data);
    });
  }

  async AddEmissions(data) {
    const body = {
      currency: data.currency,
      activationTime: parseInt((new Date()).getTime() / 1000),
      emissionRecords: data.bills.map(b => { return { ...b, bearerIdentifier: data.bearer } })
    }
    try {
      await requestService.addEmissions(body);
    } catch (e) {
      console.error(e)
      throw new Error("Failed to add emission(s): " + (e.response?.data?.error || e.message))
    }

    let retries = 0;
    while (retries < ADD_EMISSION_POLL_RETRY_COUNT) {
      await sleep(ADD_EMISSION_POLL_RETRY_DELAY_MS);
      const history = await requestService.getEmissionsHistory();
      let verifiedEmissions = 0;
      history[data.currency].forEach(history => {
        data.bills.forEach(addedEmission => {
          history.emitted.forEach(emission => {
            if (emission.firstSerialNumber === addedEmission.firstSerialNumber &&
              emission.denomination === addedEmission.denomination) {
              verifiedEmissions++;
            }
          })
        })
      })

      if (verifiedEmissions === data.bills.length) {
        return Promise.resolve();
      }

      retries++;
    }
    throw new Error("Failed to verify that emission was added successfully");
  }
}

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
