/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from 'react';
import { If, Then } from 'react-if';
import classNames from 'classnames';

export default class Toggler extends Component {

  render() {
    let togglerClasses = classNames({
      "toggler-wrapper": true,
      "form-input": true,
      'is--selected': this.props.value,
    });

    return (
      <div className={`${togglerClasses} ${this.props.classNames}`} onClick={() => this.props.onChange()}>
        <div className="toggler-label">
          <If condition={this.props.label}>
            <Then>
              <label className="text-input-label">{this.props.label}</label>
            </Then>
          </If>
          <If condition={this.props.togglerPlaceHolder}>
            <Then>
              <div className="toggler-placeholder">{this.props.togglerPlaceHolder}</div>
            </Then>
          </If>
        </div>
        <div className="toggler">
          <div className="toggler-body" />
          <div className="toggler-trigger" />
        </div>
      </div>
    )
  }
}
