import React from 'react';
import classNames from 'classnames';

function TableData({ children, className, colSpan = 1 }) {
  let tableClassName = classNames(className, {
    "table-data": true
  })

  return (
    <td className={tableClassName} style={{ flex: colSpan }}>
      {children}
    </td>
  );
}

export default TableData;
