/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

class BalanceBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavId: "day",
      timeline: 1,
    };
  }

  filterTransactions = (timeline) => {
    const { balanceHistory } = this.props.walletStore;
    const endDate = moment();
    const startDate = endDate.clone().subtract(timeline - 1, "days").startOf("day");

    return balanceHistory
      .filter((transaction) => {
        const transactionDate = moment(transaction.date, "YYYY-MM-DD HH:mm:ss");
        return transactionDate.isBetween(startDate, endDate, undefined, "[]");
      })
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  getBalanceByDate = (timeline, id) => {
    this.setState({ activeNavId: id, timeline });
  };

  getAvailablePeriods = () => {
    const { balanceHistory } = this.props.walletStore;

    return balanceHistory.length
      ? moment().diff(moment(balanceHistory[0].date, "YYYY-MM-DD HH:mm:ss"), "days")
      : 0;
  };

  calculateStartBalance = (timeline, filteredData) => {
    const startDate = moment().subtract(timeline - 1, "days").startOf("day");
    let balance = 0;

    const relevantTransactions = filteredData.filter((transaction) =>
      moment(transaction.date, "YYYY-MM-DD HH:mm:ss").isBefore(startDate)
    );

    if (relevantTransactions.length > 0) {
      balance = parseFloat(relevantTransactions[relevantTransactions.length - 1].balance);
    }

    return {
      date: startDate.format("D.M.Y HH:mm"),
      balance: balance.toFixed(2),
    };
  };

  render() {
    const { activeNavId, timeline } = this.state;
    const { walletStore } = this.props;
    const { activeCurrency, balanceHistory } = walletStore;

    if (!activeCurrency || balanceHistory.length === 0) {
      return <div>No transactions</div>;
    }

    const filteredData = this.filterTransactions(timeline).map(transaction => ({
      date: moment(transaction.date, "YYYY-MM-DD HH:mm:ss").format("D.M.Y HH:mm"),
      balance: parseFloat(transaction.balance).toFixed(2),
    }));

    const walletData = { balance: 0, ...walletStore.walletInfo };

    const startBalance = this.calculateStartBalance(timeline, balanceHistory);

    const currentBalance = {
      date: moment().format("D.M.Y HH:mm"),
      balance: parseFloat(walletData?.balanceList?.[activeCurrency]).toFixed(2),
    };

    let historyChartData = [];

    if (timeline === 1) {
      if (filteredData.length > 0 && startBalance.date === filteredData[0].date) {
        historyChartData = [...filteredData, currentBalance];
      } else {
        historyChartData = [startBalance, ...filteredData, currentBalance];
      }
    } else if (timeline === this.getAvailablePeriods()) {
      historyChartData = balanceHistory.map((transaction) => ({
        date: moment(transaction.date, "YYYY-MM-DD HH:mm:ss").format("D.M.Y HH:mm"),
        balance: parseFloat(transaction.balance).toFixed(2),
      }));
    } else {
      historyChartData = [startBalance, ...filteredData];
    }

    const maxBalance = Math.max(
      ...historyChartData.map((o) => parseFloat(o.balance))
    );

    const minBalance = Math.min(
      ...historyChartData.map((o) => parseFloat(o.balance))
    );

    const transactionSpanDiff = this.getAvailablePeriods();
    const oldestTransactionDate = moment(balanceHistory[0].date, "YYYY-MM-DD HH:mm:ss");
    const currentDate = moment();
    const showWeek = currentDate.diff(oldestTransactionDate, "days") >= 7;
    const showMonth = currentDate.diff(oldestTransactionDate, "days") >= 30;
    const showYear = currentDate.diff(oldestTransactionDate, "days") >= 365;

    const longestLabelLength = historyChartData
      .map(data => data.date)
      .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0);

    return (
      <div
        className={`balance banner ${
          this.props.activeView && "banner--hidden"
        }`}
      >
        <div className="balance-top">
          <h1>Balance history</h1>
          <div className="balance-nav">
            <div
              className={`balance-nav-btn ${
                activeNavId === "day" ? "active" : ""
              }`}
              onClick={() =>
                this.getBalanceByDate(1, "day")
              }
            >
              1d
            </div>
            {showWeek && (
              <div
                className={`balance-nav-btn ${
                  activeNavId === "week" ? "active" : ""
                }`}
                onClick={() =>
                  this.getBalanceByDate(7, "week")
                }
              >
                1w
              </div>
            )}
            {showMonth && (
              <div
                className={`balance-nav-btn ${
                  activeNavId === "month" ? "active" : ""
                }`}
                onClick={() =>
                  this.getBalanceByDate(30, "month")
                }
              >
                1m
              </div>
            )}
            {showYear && (
              <div
                className={`balance-nav-btn ${
                  activeNavId === "year" ? "active" : ""
                }`}
                onClick={() =>
                  this.getBalanceByDate(365, "year")
                }
              >
                1y
              </div>
            )}
            <div
              className={`balance-nav-btn ${
                activeNavId === "all" ? "active" : ""
              }`}
              onClick={() => this.getBalanceByDate(transactionSpanDiff, "all")}
            >
              All
            </div>
          </div>
        </div>

        <ResponsiveContainer width="100%" height={420} minHeight={420}>
          <AreaChart
            height={420}
            width={500}
            data={historyChartData}
            margin={{
              top: 10,
              right: 30,
              left: longestLabelLength,
              bottom: 0,
            }}
          >
            <CartesianGrid vertical={false} stroke="#ACB0BD54" />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="2%"
                  stopColor="rgba(28, 119, 195, 0.5)"
                  stopOpacity={1}
                />
                <stop
                  offset="98%"
                  stopColor="rgba(28, 119, 195, 0)"
                  stopOpacity={0.1}
                />
              </linearGradient>
            </defs>
            <XAxis dy={8} dataKey="date" />
            <YAxis
              dx={-6}
              domain={[
                Math.round(minBalance * 0.8),
                Math.round(maxBalance * 1.2),
              ]}
              dataKey="balance"
            />
            <Tooltip
              wrapperStyle={{ background: "#000314" }}
              contentStyle={{ color: "white", background: "#000314" }}
              itemStyle={{ color: "white", background: "#000314" }}
            />
            <Area
              dot={{ stroke: "white", strokeWidth: 2 }}
              type="linear"
              dataKey="balance"
              stroke="#8BE5DD"
              strokeWidth={2}
              fill="url(#colorUv)"
              animationBegin={20}
              animationEasing="ease-in-out"
              isAnimationActive={true}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default inject("walletStore")(observer(BalanceBanner));
