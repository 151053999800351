/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import classNames from "classnames";
import moment from "moment";
import numeral from "numeral";

import {
  Table,
  TableContent,
  TableData,
  TableHeader,
  TableRow,
  TableTitle,
} from "./../General/Table/Table";

class ViewHistory extends Component {
  render() {
    const { isFullView, isMobile, walletStore } = this.props;
    const isWide = isFullView && !isMobile;

    const filteredTransactions = walletStore.transactionInfo
      .filter(t => t.currency === walletStore.activeCurrency);

    const displayedTransactions = isFullView ? filteredTransactions : filteredTransactions.slice(0, 5);

    const listItems = (
      <Table>
        <TableHeader>
          <TableTitle colSpan={isWide || isMobile ? 1 : 2}>WALLET ID</TableTitle>
          {isWide && <TableTitle className={"time-header"}>TIME</TableTitle>}
          {!isMobile && <TableTitle colSpan={isWide || isMobile ? 1 : 2}>PAYMENT ID</TableTitle>}
          <TableTitle>AMOUNT</TableTitle>
        </TableHeader>
        <TableContent>
          {displayedTransactions
            .flatMap((transaction, transactionIdx) => {
              return transaction.otherPartyData.map((item, idx) => {
                let amountClass = classNames({
                  amount: true,
                  plus: transaction.direction === 'in',
                });

                const formattedSum = numeral(item.sum / 100.0).format("0.00");

                return (
                  <TableRow key={`${transactionIdx}-${idx}`}>
                    <TableData colSpan={isWide || isMobile ? 1 : 2} className={`code ${isWide && "full-view"}`}>
                      <span className={`${!isWide && "wallet-id"}`}>{item.otherPartyID}</span>
                      <div className="payer-info">
                        {!isWide && (
                          <time>{moment(transaction.endTime * 1000).format("llll")}</time>
                        )}
                      </div>
                    </TableData>
                    {isWide && (
                      <TableData className={"time-data"}>
                        <div className={"time"}>
                          <time>{moment(transaction.endTime * 1000).format("llll")}</time>
                        </div>
                      </TableData>
                    )}
                    {!isMobile && (
                      <TableData className={`code ${isWide && "full-view"}`} colSpan={isFullView ? 1 : 2}>
                        {transaction.paymentID}
                      </TableData>
                    )}
                    <TableData colSpan={1}>
                      <div className={amountClass}>
                        <span className="amount-item">
                          {transaction.direction === 'in' ? `+${formattedSum}` : `-${formattedSum}`}{" "}
                          {transaction.currency?.toUpperCase()}
                        </span>
                      </div>
                    </TableData>
                  </TableRow>
                );
              });
            })}
        </TableContent>
      </Table>
    );

    return (
      <div className="view-history">
        <div className="history-wrapper">
          <div className="list-wrapper">
            <div className="list list--transactions">{listItems}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default inject("walletStore", "styleStore")(observer(ViewHistory));
