/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import classNames from "classnames";
import { observer, inject } from "mobx-react";
import { TextInput } from "../General";
import Button from "../General/Button";
import numeral from "numeral";

class ViewDecommission extends Component {
  constructor(props) {
    super(props);

    this.components = {
      text: TextInput,
    };

    this.state = this.initializeState(props);

    this.makeDecommission = this.makeDecommission.bind(this);
    this.hideView = this.hideView.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  initializeState(props) {
    const { nominalValue, total, billDetails } = props;
    return {
      denomination: nominalValue || "",
      quantity: "",
      availableCount: total || 0,
      billDetails: billDetails || [],
      isSubmitting: false,
      form: this.initializeForm(nominalValue, total),
    };
  }

  initializeForm(nominalValue, total) {
    return [
      {
        type: "text",
        name: "denomination",
        label: "Denomination",
        classNames: "inline",
        value: numeral(nominalValue / 100).format("0.00"),
        disabled: true,
        errorMessage: "",
        rules: [],
      },
      {
        type: "text",
        name: "quantity",
        label: "Quantity",
        classNames: "inline",
        inputType: "number",
        value: "",
        errorMessage: "",
        rules: [
          (v) => !isNaN(parseInt(v)) || "Quantity must be valid",
          (v) => parseInt(v) > 0 || "Quantity must be greater than 0",
          (v) => parseInt(v) <= total || `Quantity exceeds available count of ${total}.`,
        ],
      },
    ];
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.setState(this.initializeState(this.props));
    }
  }

  handleChange(value, name) {
    this.setState((prevState) => {
      const form = prevState.form.map((field) => {
        if (field.name === name) {
          return { ...field, value, errorMessage: "" };
        }
        return field;
      });
      return { form };
    });
  }

  validateForm() {
    let valid = true;
    const form = this.state.form.map((field) => {
      if (field.rules) {
        field.errorMessage = "";
        for (let i = 0; i < field.rules.length; i++) {
          const validate = field.rules[i];
          const validationValue = validate(field.value);
          if (typeof validationValue !== "boolean") {
            field.errorMessage = validationValue;
            valid = false;
            break;
          }
        }
      }
      return field;
    });
    this.setState({ form });
    return valid;
  }

  makeDecommission() {
    if (!this.validateForm()) return;

    const { userStore } = this.props;
    const { form, billDetails } = this.state;
    const quantity = parseInt(form.find((field) => field.name === "quantity").value, 10);
    const walletID = userStore.user.walletID;

    let remainingQuantity = quantity;
    const dataList = billDetails.map(detail => {
      const data = {
        bearerIdentifier: walletID,
        shardingRecordId: detail.shardingRecordId,
        count: Math.min(remainingQuantity, detail.count),
      };
      remainingQuantity -= data.count;
      return data;
    }).filter(data => data.count > 0);

    this.setState({ isSubmitting: true });

    Promise.all(dataList.map(data => this.props.exchangeStore.decommissionBills(data)))
      .then(() => {
        this.setState({ isSubmitting: false });
        this.props.alertsStore.setSuccess({
          title: "Bills Decommissioned Successfully",
          body: "The bills have been decommissioned.",
        });
        this.props.exchangeStore.GetExchangeBills(this.props.exchangeStore.exchangeViewCurrency);
        this.hideView();
      }).catch((error) => {
        this.setState({ isSubmitting: false });
        this.props.alertsStore.setError({
          title: "Error Decommissioning Bills",
          body: error.message,
        });
      });
  }

  hideView() {
    this.props.hideDecommissionView();
  }

  render() {
    const { visible } = this.props;
    const { form, isSubmitting, availableCount } = this.state;

    let viewDecommissionClasses = classNames({
      "view-decommission": true,
      "is--visible": visible,
    });

    const formItems = form.map((item) => {
      const FormComponent = this.components[item.type];
      return (
        <div key={item.name}>
          {item.name === "quantity" && (
            <div className="t-orange-gradient t-16-b">
              Available bills: {availableCount}
            </div>
          )}
          <FormComponent
            key={item.name}
            {...item}
            onChange={(value) => this.handleChange(value, item.name)}
            value={item.value}
            disabled={item.disabled}
          />
          {item.errorMessage && (
            <div className="error-message">{item.errorMessage}</div>
          )}
        </div>
      );
    });

    return (
      <div className={viewDecommissionClasses}>
        <div className="view-header">
          <div className="close-btn" onClick={this.hideView}>Close X</div>
          <div className="title">Decommission Bills</div>
        </div>
        <div className="view-content">
          {formItems}
          <Button
            cyber
            transparent
            className="scan-btn decommission-btn"
            label="Confirm"
            onClick={this.makeDecommission}
            disabled={isSubmitting}
            working={isSubmitting}
          />
        </div>
      </div>
    );
  }
}

export default inject("userStore", "exchangeStore", "alertsStore")(observer(ViewDecommission));
