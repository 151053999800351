/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import { observer, inject } from "mobx-react";

import AddEmission from "./components/AddEmission";
import EmissionHistory from "./components/EmissionHistory";
import EmittedBills from "./components/EmittedBills";

class ViewEmissions extends Component {
  render() {
    const { isFullView, isMobile } = this.props;

    return (
      <>
        <div className="two-col">
          <AddEmission
            setActiveView={(view) => this.props.setActiveView(view)}
          />
          <EmittedBills
            isMobile={isMobile}
            isFullView={isFullView}
          />
        </div>
        <EmissionHistory
          isMobile={isMobile}
          isFullView={isFullView}
        />
      </>
    );
  }
}

export default inject("walletStore")(observer(ViewEmissions));
