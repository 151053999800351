/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from 'react';
import classNames from 'classnames';
import { If, Then, Else } from 'react-if';

export default class Modal extends Component {

  render() {
    let modalClass = classNames({
      "modal": true,
      "visible": this.props.visible,
      "modal-message--success": this.props.type === 'success',
      "modal-message--error": this.props.type === 'error',
    });

    return (
      <div className={modalClass}>
        <div className="modal-message">
          <div className="icon">
            <If condition={this.props.type === 'success'}>
              <Then>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                  <path d="M16,2A14,14,0,1,0,30,16,14,14,0,0,0,16,2ZM14,21.5908l-5-5L10.5906,15,14,18.4092,21.41,11l1.5957,1.5859Z"></path>
                  <path d="M14 21.591L9 16.591 10.591 15 14 18.409 21.41 11 23.005 12.585 14 21.591z" fill="white"></path>
                </svg>
              </Then>
              <Else>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px">
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" />
                </svg>
              </Else>
            </If>
          </div>
          <div className="modal-text">
            <div className="txt--m title">{this.props.message.title}</div>
            <p>{this.props.message.body}</p>
          </div>
        </div>
      </div>
    )
  }
}
