import React from 'react';

import TableContent from './components/TableContent';
import TableData from './components/TableData';
import TableHeader from './components/TableHeader';
import TableRow from './components/TableRow';
import TableTitle from './components/TableTitle';

function Table({ children }) {
  return <table className="table">{children}</table>;
}

export { Table, TableContent, TableData, TableHeader, TableRow, TableTitle };
