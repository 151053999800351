/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import Select from "react-select";
import Button from "../General/Button";
import {
  Table,
  TableContent,
  TableData,
  TableHeader,
  TableRow,
  TableTitle,
} from "../General/Table/Table";
import numeral from "numeral";
import ViewDecommission from './ViewDecommission';

class ViewExchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRow: null,
      showDecommissionView: false,
      decommissionProps: {},
    };

    this.handleShowPaymentView = this.handleShowPaymentView.bind(this);
    this.handleAddNewDenomination = this.handleAddNewDenomination.bind(this);
    this.handleShowDecommissionView = this.handleShowDecommissionView.bind(this);
    this.hideDecommissionView = this.hideDecommissionView.bind(this);
  }

  handleCurrencyChange = (selectedOption) => {
    const { exchangeStore, walletStore } = this.props;
    exchangeStore.setExchangeViewCurrency(selectedOption.value);
    exchangeStore.GetExchangeBills(selectedOption.value);
    walletStore.GetBillsList(selectedOption.value);
  }

  toggleItem = (id) => {
    this.setState((prevState) => ({
      expandedRow: prevState.expandedRow === id ? null : id,
    }));
  }

  handleShowPaymentView(bill) {
    const newProps = {
      isViewExchange: true,
      billDetails: bill,
      exchangeId: this.props.exchangeStore.exchangeId,
    };
    this.props.togglePayViewVisibility(newProps);
  }

  handleAddNewDenomination() {
    const newProps = {
      isViewExchange: true,
      billDetails: null,
      exchangeId: this.props.exchangeStore.exchangeId,
      newDenomination: true,
    };
    this.props.togglePayViewVisibility(newProps);
  }

  handleShowDecommissionView(bill) {
    const newProps = {
      billDetails: bill.details,
      nominalValue: bill.nominalValue,
      total: bill.total
    };
    this.setState({ showDecommissionView: true, decommissionProps: newProps });
  }  

  hideDecommissionView() {
    this.setState({ showDecommissionView: false, decommissionProps: {} });
  }

  groupBillsByNominalValue(bills) {
    return bills.reduce((acc, bill) => {
      const { nominalValue, shardingRecordId, count } = bill;
      if (!acc[nominalValue]) {
        acc[nominalValue] = { nominalValue, total: 0, details: [] };
      }
      acc[nominalValue].total += count;
      acc[nominalValue].details.push({ shardingRecordId, count });
      return acc;
    }, {});
  }

  calculateStatusColor(count, maxCount) {
    // Determine the color based on the percentage of the count relative to maxCount
    // maxCount - highest quantity of any bill denomination currently in the exchange machine
    const percentage = count / maxCount;
    return percentage < 0.2 ? 'red' : (percentage < 0.8 ? 'orange' : 'green');
  }

  async shareExchangeId(id) {
    const el = document.createElement("textarea");
    el.value = id;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  render() {
    const { exchangeStore, walletStore } = this.props;
    const { exchangeViewCurrency, exchangeBills, exchangeCurrencyList, exchangeId } = exchangeStore;
    const { billsList = [] } = walletStore;

    const currencyOptions = exchangeCurrencyList.map((currency) => ({
      value: currency,
      label: currency.toUpperCase(),
    }));

    const sortedBills = exchangeBills.active
      ? exchangeBills.active.slice().sort((a, b) => a.nominalValue - b.nominalValue)
      : [];

    const groupedBills = this.groupBillsByNominalValue(sortedBills);
    const maxCount = Math.max(...Object.values(groupedBills).map(bill => bill.total), 1);

    return (
      <div className="banner no-bg exchange-view">
        <div className="banner">
          <div className="exchange-top">
            <h1>Exchange Machine</h1>
            {exchangeCurrencyList.length > 1 && (
              <Select
                className="language-select"
                classNamePrefix="select"
                menuPlacement="auto"
                options={currencyOptions}
                onChange={this.handleCurrencyChange}
                defaultValue={currencyOptions.find((option) => option.value === exchangeViewCurrency) || currencyOptions[0]}
              />
            )}
        </div>
          <Table>
            <TableHeader>
              <TableTitle>BILL</TableTitle>
              <TableTitle></TableTitle>
              <TableTitle>STATUS</TableTitle>
              <TableTitle>QUANTITY</TableTitle>
              <TableTitle>AMOUNT</TableTitle>
              <TableTitle></TableTitle>
              <TableTitle></TableTitle>
            </TableHeader>

            <TableContent>
              {Object.values(groupedBills).map((bill, index) => {
                const uniqueKey = `${bill.nominalValue}-${index}`;
                const isExpanded = this.state.expandedRow === uniqueKey;
                const walletBill = billsList.find(b => b.denomination === bill.nominalValue);
                const isDisabled = !walletBill || walletBill.count === 0;
                const statusColor = this.calculateStatusColor(bill.total, maxCount);

                return (
                  <React.Fragment key={uniqueKey}>
                    <TableRow
                      key={uniqueKey}
                      onClick={() => this.toggleItem(uniqueKey)}
                      className={isExpanded ? 'is--expanded' : ''}
                    >
                      <TableData className="t-bold">{numeral(bill.nominalValue / 100).format("0.00")}</TableData>
                      <TableData className="t-orange-gradient see-all">
                        {isExpanded ? "Close" : "See All"}
                      </TableData>
                      <TableData>
                        <span className={`indicator-round`} style={{ background: statusColor }}></span>
                      </TableData>
                      <TableData>{bill.total}</TableData>
                      <TableData>
                        <div className="t-blue-gradient t-bold">
                          {numeral(bill.nominalValue * bill.total / 100).format("0.00")}
                        </div>
                      </TableData>
                      <TableData colSpan={2}>
                        <div className="buttons-group">
                          <Button
                            small
                            cyber
                            label="Add bills"
                            className="add-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.handleShowPaymentView(bill);
                            }}
                            disabled={isDisabled}
                          />
                          <Button
                            small
                            cyber
                            label="Remove bills"
                            className="add-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              this.handleShowDecommissionView(bill);
                            }}
                          />
                        </div>
                      </TableData>
                    </TableRow>
                    {isExpanded && (
                      <TableRow 
                        key={`${uniqueKey}-expanded`}
                        className="row-bottom"
                      >
                        <TableData colSpan="7">
                          <div className="pad__b-16">
                            {bill.details.map((detail, idx) => (
                              <div key={idx} className="txt--left">
                                Shard: {detail.shardingRecordId} Count: {detail.count}
                              </div>
                            ))}
                          </div>
                        </TableData>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
            </TableContent>
          </Table>
        </div>
        <div className="mar__t-32"></div>
        <div className="banner exchange-id w-50">
            <h1>Exchange Machine ID</h1>
            <div className="list-group">
              <div className="list-item">{exchangeId}</div>
            </div>
            <Button
              cyber
              transparent
              label="Copy Exchange Machine ID"
              className="copy-key"
              onClick={() => {
                this.shareExchangeId(exchangeId);
              }}
            />
        </div>
  
        <ViewDecommission
          visible={this.state.showDecommissionView}
          hideDecommissionView={this.hideDecommissionView}
          {...this.state.decommissionProps}
        />
      </div>
    );
  }
}

export default inject("exchangeStore", "styleStore", "walletStore")(observer(ViewExchange));
