/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from 'react';
import { If, Then } from 'react-if';
import classNames from 'classnames';

export default class Textarea extends Component {

  render() {

    let inputClass = classNames({
      "form-input": true,
      "input--is-error": this.props.errorMessage
    });

    return (
      <div className={`${inputClass} ${this.props.classNames}`}>
        <If condition={this.props.label}>
          <Then>
            <label className="text-input-label">{this.props.label}</label>
          </Then>
        </If>
        <div
          className="text-input-wrapper"
        >
          <textarea
            rows={4}
            className={this.props.classNames}
            value={this.props.value}
            onChange={(e) => this.props.onChange(e.target.value)}
            disabled={this.props.disabled}
          />
        </div>
        <div className="error-message">{this.props.errorMessage}</div>
      </div>
    )
  }
}
