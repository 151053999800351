/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import { observable, action, makeObservable, runInAction, reaction } from "mobx";
import moment from "moment";
import { requestService, webSocketService } from "./../services";

export default class WalletStore {
  currencyList = [];
  walletInfo = {};
  walletsList = [];
  transactionInfo = [];
  billsList = [];
  balanceHistory = [];
  activeCurrency = null;
  payViewProps = {};
  balance = 0;
  serialsListCallback = null; 

  constructor() {
    makeObservable(this, {
      currencyList: observable,
      walletInfo: observable,
      walletsList: observable,
      transactionInfo: observable,
      billsList: observable,
      balanceHistory: observable,
      activeCurrency: observable,
      payViewProps: observable,
      balance: observable,
      setCurrencyList: action,
      setActiveCurrency: action,
      setWalletInfo: action,
      setTransactionInfo: action,
      setBillsList: action,
      setBalanceHistory: action,
      SetBalance: action,
      GetCurrencies: action,
      GetWalletHistory: action,
      MakeAnPayment: action,
      GetBillsList: action,
      GetBillDenominationSerials: action,
      setPayViewProps: action,
      clearPayViewProps: action,
      updateBalance: action,
      requestBalance: action,
      calculateBalanceHistory: action,
      handleWebSocketMessage: action,
      handlePaymentResponse: action,
      handlePaymentNotification: action,
    });

    reaction(
      () => this.activeCurrency,
      (currency) => {
        if (currency) {
          this.requestBalance();
          this.GetWalletHistory();
          this.GetBillsList(currency);
        }
      }
    );
  }

  setCurrencyList(list = []) {
    this.currencyList = list || [];
  }

  setActiveCurrency(currency) {
    this.activeCurrency = currency || '';
  }

  setWalletInfo(wallet) {
    this.walletInfo = {
      ...wallet,
      created: moment(wallet.created).format("YYYY-MM-DD HH:mm"),
    };
  }

  setTransactionInfo(transactions) {
    this.transactionInfo = transactions;
    this.calculateBalanceHistory();
  }

  setBillsList(list = []) {
    this.billsList = list || [];
  }

  setBalanceHistory(list = []) {
    this.balanceHistory = list || [];
  }

  setPayViewProps(props) {
    this.payViewProps = props;
  }

  clearPayViewProps() {
    this.payViewProps = {};
  }

  requestBalance() {
    if (!this.activeCurrency) {
      console.error("Active currency is not set.");
      return;
    }
    
    const request = {
      method: "Request",
      type: "Balance",
      body: { currency: this.activeCurrency }
    };
    webSocketService.sendRequest(request);
  }

  GetCurrencies() {
    return requestService.getCurrencies().then(response => {
      return response.currencies;
    });
  }

  GetWalletHistory() {
    if (!this.activeCurrency) {
      console.error("Active currency is not set.");
      return;
    }

    const request = {
      method: "Request",
      type: "Statement",
      body: {
        currency: this.activeCurrency,
        pageSize: 50
      }
    };
    webSocketService.sendRequest(request);
  }

  calculateBalanceHistory() {
    const balanceHistory = [];
    let currentBalance = 0;

    const transactions = this.transactionInfo
      .filter(transaction => transaction.currency === this.activeCurrency)
      .sort((a, b) => new Date(a.startTime * 1000) - new Date(b.startTime * 1000));

    if (transactions.length > 0) {
      balanceHistory.push({
        date: moment.unix(transactions[0].startTime).startOf('day').format("YYYY-MM-DD HH:mm:ss"),
        balance: currentBalance,
      });

      transactions.forEach(transaction => {
        const { startTime, direction, otherPartyData } = transaction;
        otherPartyData.forEach(data => {
          if (direction === 'in') {
            currentBalance += data.sum;
          } else if (direction === 'out') {
            currentBalance -= data.sum;
          }
          balanceHistory.push({
            date: moment.unix(startTime).format("YYYY-MM-DD HH:mm:ss"),
            balance: currentBalance / 100,
          });
        });
      });
    }

    runInAction(() => {
      this.setBalanceHistory(balanceHistory);
    });
  }

  SetBalance(balance) {
    const convertedBalance = balance / 100.0;
    this.walletInfo = {
      ...this.walletInfo,
      balance: convertedBalance,
    };
  }

  GetBillsList(currency = this.activeCurrency) {
    if (!currency) {
      console.error("Currency is not set.");
      return;
    }
    
    const request = {
      method: "Request",
      type: "BillsCount",
      body: { currency: currency }
    };
    webSocketService.sendRequest(request);
  }

  GetBillDenominationSerials(denomination, currency, pageSize = 50, pageIndex = 0, callback) {
    this.serialsListCallback = callback;
    const request = {
      method: "Request",
      type: "Bills",
      body: {
        currency: currency,
        denomination: denomination,
        pageIndex: pageIndex,
        pageSize: pageSize
      }
    };
    webSocketService.sendRequest(request);
  }

  handleWebSocketMessage(data) {
    switch (data.type) {
      case "Balance":
        this.updateBalance(data.body.currency, data.body.balance);
        break;
      case "Statement":
        this.setTransactionInfo(data.body.transactions);
        this.calculateBalanceHistory();
        break;
      case "BillsCount":
        this.setBillsList(data.body.billsCount);
        break;
      case "Bills":
        if (this.serialsListCallback) {
          this.serialsListCallback(data.body.serials);
        }
        break;
      case "Payment":
        this.handlePaymentResponse(data.body);
        break;
      default:
        console.log("Unknown message type:", data.type);
    }
  }

  updateBalance(currency, balance) {
    const convertedBalance = balance / 100.0;
    this.walletInfo = {
      ...this.walletInfo,
      balanceList: {
        ...this.walletInfo.balanceList,
        [currency]: convertedBalance
      }
    };
  }

  handlePaymentResponse(body) {
    if (body.error) {
      console.error("Payment Error:", body.error);
    } else {
      this.updateBalance(body.currency, body.balance);
      this.GetBillsList();
      this.GetWalletHistory();
    }
  }

  handlePaymentNotification(body, alertStore) {
    if (body.amount && body.currency) {
      const message = `You received ${body.amount / 100.0} ${body.currency.toUpperCase()}`;

      alertStore.setSuccess({
        title: "New payment received",
        body: message,
      });
    }
    this.requestBalance();
    this.GetWalletHistory();
  }

  MakeAnPayment(form) {
    const request = {
      method: "Request",
      type: "Payment",
      body: {
        comment: form.comment,
        referenceNumber: form.referenceNumber,
        payeeID: form.payeeID,
        currency: form.currency,
        amount: parseInt(form.amount, 10),
      }
    };
  
    if (form.denomination) {
      request.body.denomination = parseInt(form.denomination, 10);
    }
  
    return new Promise((resolve, reject) => {
      const originalHandlePaymentResponse = this.handlePaymentResponse;
      this.handlePaymentResponse = (body) => {
        originalHandlePaymentResponse.call(this, body);
        if (body.error) {
          reject(body.error);
        } else {
          resolve(body);
        }
        this.handlePaymentResponse = originalHandlePaymentResponse;
      };
      webSocketService.sendRequest(request);
    });
  }
}