/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import {
  Table,
  TableContent,
  TableData,
  TableHeader,
  TableRow,
  TableTitle,
} from "./../General/Table/Table";

const ViewGateways = ({ emissionsStore }) => {
  useEffect(() => {
    const fetchData = async () => {
      await emissionsStore.GetEmissionsGatewaysInfo();
    };

    fetchData();
  }, [emissionsStore]);

  const gateways = Object.values(emissionsStore.gatewaysInfo).flatMap(item =>
    item.jobRecord.filter(job => job.name === "gateway")
  );

  return (
    <div className="banner gateways-banner mar__t-32">
      <h1>Gateways</h1>

      <Table>
        <TableHeader>
          <TableTitle>NAME</TableTitle>
          <TableTitle colSpan={0}>ACTIVE</TableTitle>
        </TableHeader>
        <TableContent>
          {gateways.map((item, idx) => (
            <TableRow key={idx}>
              <TableData colSpan={1}>
                <div className="payer-info">
                  <span className="payer-name">{item.id}</span>
                </div>
              </TableData>
              <TableData colSpan={0}>
                <span
                  className={`indicator-round mar__r-16 ${
                    item.status === "running" ? "green" : "red"
                  }`}
                ></span>
              </TableData>
            </TableRow>
          ))}
        </TableContent>
      </Table>
    </div>
  );
};

export default inject("emissionsStore")(observer(ViewGateways));
