/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { Component } from "react";
import classNames from "classnames";
import { observer, inject } from "mobx-react";
import { TextInput, Textarea, Toggler, Tabs } from "./../General";
import { If, Then, Else } from "react-if";
import { BrowserMultiFormatReader, DecodeHintType } from "@zxing/library";
import BarcodeFormat from "@zxing/library/esm/core/BarcodeFormat";
import numeral from "numeral";
import { isHex } from "./../../services/utils";
import Button from "./../General/Button";

class ViewPay extends Component {
  constructor(props) {
    super(props);

    this.components = {
      text: TextInput,
      textarea: Textarea,
      toggler: Toggler,
    };

    this.state = this.initializeState(props);

    this.timer = null;
    this.scanTimer = null;
    this.videoRef = React.createRef();
    this.canvasRef = React.createRef();
  }

  initializeState(props) {
    const isViewExchange = props.isViewExchange;
    const isMobile = props.isMobile;
    return {
      amount: numeral(0).format("0.00"),
      activeTab: !isMobile ? "manual" : "qr",
      publicKey: isViewExchange ? props.exchangeId : "",
      showName: !props.walletStore.walletInfo.anonymous,
      scanning: false,
      qrResult: false,
      canvasHidden: true,
      reader: null,
      qrReaderResult: false,
      isSubmitting: false,
      denomination: props.billDetails ? props.billDetails.nominalValue : "",
      quantity: "",
      form: isViewExchange ? this.initializeExchangeForm(props) : this.initializeWalletForm(),
      comment: "",
      referenceNumber: "",
    };
  }

  initializeExchangeForm(props) {
    const denomination = props.billDetails ? props.billDetails.nominalValue : "";
    return [
      {
        type: "text",
        name: "denomination",
        label: "Denomination",
        classNames: "inline",
        value: numeral(denomination / 100).format("0.00"),
        disabled: true,
      },
      {
        type: "text",
        name: "quantity",
        placeholder: "0",
        label: "Quantity",
        classNames: "inline",
        inputType: "number",
        errorMessage: "",
        rules: [
          (v) => !isNaN(parseInt(v)) || "Quantity must be valid",
          (v) => parseInt(v) > 0 || "Quantity must be greater than 0",
          (v) => this.validateQuantity(v) || "Insufficient bills available",
        ],
      },
    ];
  }

  validateQuantity(quantity) {
    const denomination = this.state.denomination;
    const availableBills = this.props.walletStore.billsList.find(
      (bill) => bill.denomination === parseInt(denomination)
    );
    if (!availableBills) return false;
    return quantity <= availableBills.count;
  }

  initializeWalletForm() {
    return [
      {
        type: "textarea",
        name: "publicKey",
        label: "Receiver's Wallet ID",
        classNames: "inline",
        errorMessage: "",
        rules: [
          (v) => !!v || "Wallet ID is required",
          (v) => isHex(v) || "Wallet ID must be valid base16",
        ],
      },
      {
        type: "text",
        name: "amount",
        label: "Amount",
        classNames: "inline",
        inputType: "number",
        errorMessage: "",
        rules: [
          (v) => !isNaN(parseFloat(v)) || "Amount must be valid",
          (v) => parseFloat(v) !== 0 || "Minimum amount is 0.01",
        ],
      },
      {
        type: "text",
        name: "comment",
        label: "Comment",
        classNames: "inline",
        errorMessage: "",
        rules: [
          (v) => v.trim() !== "" || "Comment is required",
          (v) => {
            const encoder = new TextEncoder();
            const byteLength = encoder.encode(v).length;
            return byteLength <= 32 || "Comment must be at most 32 characters long";
          },
        ],
      },
      {
        type: "text",
        name: "referenceNumber",
        label: "Reference number",
        classNames: "inline",
        inputType: "text",
        errorMessage: "",
        rules: [
          (v) =>
            !v ||
            /^[0-9]{1,16}$/.test(v) ||
            "Reference number must be at most 16 digits long",
        ],
      },
    ];
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.setState(this.initializeState(this.props));
    }
  }

  startScan() {
    let video = this.videoRef.current;
    var canvas = document.getElementById("make-payment");

    const hints = new Map();
    const formats = [BarcodeFormat.QR_CODE, BarcodeFormat.DATA_MATRIX];

    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    const reader = new BrowserMultiFormatReader(hints);

    this.setState({ reader: reader });

    const tick = () => {
      var ctx = canvas.getContext("2d");
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      this.state.scanning && requestAnimationFrame(tick);
    };

    const scan = () => {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      let img = canvas.toDataURL("image/png");

      if (img !== "data:,") {
        try {
          this.state.reader
            .decodeFromImage(null, img)
            .then((data) => {
              let text = data.getText();

              let result = JSON.parse(text);
              video.srcObject.getTracks().forEach((track) => {
                track.stop();
              });
              this.setState({
                qrReaderResult: true,
                publicKey: result.payeeId || "",
                amount: result.amount || 0,
                scanning: false,
                qrResult: false,
                canvasHidden: true,
              });
            })
            .catch((e) => {
              this.timer = setTimeout(scan, 300);
            });
        } catch (e) {
          console.info(e);
        }
      } else {
        this.timer = setTimeout(scan, 300);
      }
    };

    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((stream) => {
        this.setState({
          scanning: true,
          qrResult: true,
          canvasHidden: false,
        });
        canvas.hidden = false;
        video.setAttribute("playsinline", true);
        video.srcObject = stream;
        video.play();
        tick();
        scan();
      });
  }

  onChange(value, type) {
    if (type === "showName") {
      this.setState((state) => ({
        showName: !state.showName,
      }));
    } else {
      let input = {};
      if (type === "referenceNumber") {
        input[type] = value.replace(/[^0-9]/g, "");
      } else {
        input[type] = value;
      }
      this.setState({
        ...input,
      });
    }
  }

  formatNumeric() {
    this.setState((state) => ({
      amount: numeral(state.amount).format("0.00", Math.floor),
    }));
  }

  stopVideo() {
    let video = this.videoRef.current;
    if (video && video.srcObject) {
      video.srcObject.getTracks().forEach((track) => {
        track.stop();
      });

      video.srcObject = null;
    }
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  hideView() {
    clearTimeout(this.scanTimer);
    this.stopVideo();
    this.props.hidePayView();

    setTimeout(() => {
      this.setState(this.initializeState(this.props));
    }, 900);
  }

  makePayment() {
    const validate = () => {
      let valid = true;
      let fields = this.state.form;

      for (let i = 0; i < fields.length; i++) {
        let field = fields[i];

        if (field.rules) {
          field.errorMessage = "";
          for (let j = 0; j < field.rules.length; j++) {
            let validate = field.rules[j];
            let validationValue = validate(this.state[field.name]);

            if (typeof validationValue !== "boolean") {
              field.errorMessage = validationValue;
              valid = false;
            }
          }
        }
      }

      this.setState({
        form: fields,
      });

      return valid;
    };

    if (validate()) {
      const { isViewExchange } = this.props;
      let amount;

      if (isViewExchange) {
        const nominalValue = parseFloat(this.props.billDetails.nominalValue);
        const quantity = parseInt(this.state.quantity, 10);
        amount = nominalValue * quantity;
      } else {
        amount = parseFloat(this.state.amount) * 100;
      }

      let form = {
        currency: this.props.walletStore.activeCurrency,
        payeeID: this.state.publicKey,
        amount: parseInt(amount, 10),
        comment: isViewExchange ? "Add bills" : this.state.comment,
        referenceNumber: this.state.referenceNumber,
        exposePayerID: !this.state.showName,
        useOnePhaseCommit: false,
      };

      if (isViewExchange) {
        form.denomination = parseInt(this.props.billDetails.nominalValue, 10);
      }

      this.setState({
        isSubmitting: true,
      });

      this.props.walletStore.MakeAnPayment(form).then((response) => {
        this.setState({
          isSubmitting: false,
        });
        this.props.alertsStore.setSuccess({
          title: isViewExchange
            ? "Bills Added Successfully"
            : "Payment Successful",
          body: isViewExchange
            ? "The bills have been added to the exchange machine."
            : "Thank you!",
        });
        if (isViewExchange) {
          this.props.exchangeStore.GetExchangeBills(this.props.exchangeStore.exchangeViewCurrency);
        }
        this.hideView();
      }).catch((error) => {
        this.setState({
          isSubmitting: false,
        });
        this.props.alertsStore.setError({
          title: error.error,
          body: error.message,
        });
      });
    }
  }

  updateActiveTab(value) {
    this.setState({
      activeTab: value,
    });

    if (this.state.activeTab === "manual" && value === "qr") {
      this.setState({
        qrReaderResult: false,
        scanning: false,
        qrResult: false,
        canvasHidden: true,
      });
    } else {
      this.stopVideo();
    }
  }

  render() {
    const isViewExchange = this.props.isViewExchange;

    let viewPayClasses = classNames({
      "view-pay": true,
      "is--visible": this.props.visible,
    });

    const formItems = this.state.form.map((item) => {
      const FormComponent = this.components[item.type];

      let availableBillsCount = 0;
      if (item.name === "quantity") {
        const denomination = parseInt(this.state.denomination);
        const bill = this.props.walletStore.billsList.find(
          (bill) => bill.denomination === denomination
        );
        availableBillsCount = bill ? bill.count : 0;
      }
      return (
        <div key={item.name}>
          {(item.name === "amount") && (
            <div className="t-orange-gradient t-16-b">
              Available funds: {this.props.balance}
            </div>
          )}
          {item.name === "quantity" && (
            <div className="t-orange-gradient t-16-b">
              Available bills: {availableBillsCount}
            </div>
          )}
          <FormComponent
            key={item.name}
            {...item}
            onChange={(value) => this.onChange(value, item.name)}
            value={item.name === "denomination" ? item.value : this.state[item.name]}
            onBlur={(e) => this.formatNumeric(e)}
            disabled={item.disabled}
          />
        </div>
      );
    });

    return (
      <div className={viewPayClasses}>
        <div className="view-header">
          <div className="close-btn" onClick={() => this.hideView()}>
            Close X
          </div>
          <div className="title">{isViewExchange ? "Add Bills" : "Make payment"}</div>
          {!isViewExchange && (
            <Tabs
              tabs={[
                { label: "Scan", value: "qr" },
                { label: "Manual", value: "manual" },
              ]}
              active={this.state.activeTab}
              onChange={(value) => this.updateActiveTab(value)}
            />
          )}
        </div>
        <div className="view-content">
          <If condition={this.state.activeTab === "qr" && !isViewExchange}>
            <Then>
              <If condition={!this.state.qrReaderResult}>
                <Then>
                  <div className="canvas-wrapper">
                    <canvas
                      id="make-payment"
                      hidden={this.state.canvasHidden}
                      ref={this.canvasRef}
                    />
                    <video ref={this.videoRef} />
                  </div>
                  <Button
                    cyber
                    transparent
                    className="scan-btn"
                    label="Click to scan"
                    onClick={() => this.startScan()}
                  />
                </Then>
                <Else>
                  {formItems}
                  <Button
                    cyber
                    transparent
                    className="scan-btn"
                    label="Confirm"
                    onClick={() =>
                      this.makePayment(
                        this.props.walletStore.walletInfo.payeeId
                      )
                    }
                    disabled={this.state.isSubmitting}
                    working={this.state.isSubmitting}
                  />
                </Else>
              </If>
            </Then>
            <Else>
              {formItems}
              <Button
                cyber
                transparent
                className="scan-btn"
                label="Confirm"
                onClick={() =>
                  this.makePayment(this.props.walletStore.walletInfo.payeeId)
                }
                disabled={this.state.isSubmitting}
                working={this.state.isSubmitting}
              />
            </Else>
          </If>
        </div>
      </div>
    );
  }
}

export default inject("userStore", "walletStore", "alertsStore", "exchangeStore")(observer(ViewPay));
