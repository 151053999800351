/*
 * GUARDTIME CONFIDENTIAL
 *
 * Copyright 2008-2021 Guardtime, Inc.
 * All Rights Reserved.
 *
 * All information contained herein is, and remains, the property
 * of Guardtime, Inc. and its suppliers, if any.
 * The intellectual and technical concepts contained herein are
 * proprietary to Guardtime, Inc. and its suppliers and may be
 * covered by U.S. and foreign patents and patents in process,
 * and/or are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Guardtime, Inc.
 * "Guardtime" and "KSI" are trademarks or registered trademarks of
 * Guardtime, Inc., and no license to trademarks is granted; Guardtime
 * reserves and retains all trademark rights.
 */

import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import Select from "react-select";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const ViewMetrics = ({ emissionsStore }) => {
  const [activeNavId, setActiveNavId] = useState("all");
  const [timeline, setTimeline] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!emissionsStore.dhsEmissions || Object.keys(emissionsStore.dhsEmissions).length === 0) {
        setLoading(true);
        await emissionsStore.GetDHSEmissions();
        setLoading(false);
      } else {
        setLoading(false);
      }
    };

    fetchData();
  }, [emissionsStore]);

  const handleCurrencyChange = (selectedOption) => {
    emissionsStore.setMetricsViewCurrency(selectedOption.value);
  };

  const filterEmissionsData = (timeline) => {
    const { dhsEmissions, metricsViewCurrency } = emissionsStore;

    const data = transformData(dhsEmissions, metricsViewCurrency);
    if (!timeline) {
      return data;
    }

    const endDate = moment();
    const startDate = endDate.clone().subtract(timeline, "days");

    const filteredData = data.filter((item) => {
      const itemDate = moment(item.date);
      return itemDate.isBetween(startDate, endDate, undefined, "[]");
    });

    if (filteredData.length > 0 && filteredData[0].date !== startDate.format("YYYY-MM-DD")) {
      filteredData.unshift({
        date: startDate.format("YYYY-MM-DD"),
        fullDate: startDate.format("D.M.Y HH:mm"),
        amount: 0
      });
    }

    return filteredData;
  };

  const getEmissionsByDate = (timeline, id) => {
    setActiveNavId(id);
    setTimeline(timeline);
  };

  const transformData = (data, selectedCurrency) => {
    const toLowerCaseCurrency = selectedCurrency?.toLowerCase();

    const filteredData = Object.values(data)
      .filter(({ currency }) => currency && currency.toLowerCase() === toLowerCaseCurrency)
      .sort(({ activatedAt: a }, { activatedAt: b }) => new Date(a) - new Date(b));

    let cumulativeSum = 0;
    const cumulativeData = filteredData.map(({ billCount, denomination, activatedAt }) => {
      const amount = billCount * (denomination / 100);
      cumulativeSum += amount;

      return {
        date: moment(activatedAt).format("YYYY-MM-DD"),
        fullDate: moment(activatedAt).format("D.M.Y HH:mm"),
        amount: parseFloat(cumulativeSum.toFixed(2))
      };
    });

    if (cumulativeData.length > 0) {
      const firstDate = moment(cumulativeData[0].date).subtract(1, 'days').format("YYYY-MM-DD");
      cumulativeData.unshift({ date: firstDate, fullDate: firstDate, amount: 0 });
    }

    return cumulativeData;
  };

  const getAvailablePeriods = () => {
    const { dhsEmissions } = emissionsStore;
    const currentDate = moment();

    const hasDataForPeriod = (startDate) => {
      const result = Object.values(dhsEmissions).some(item => {
        const itemDate = moment(item.activatedAt);
        return itemDate.isBetween(startDate, currentDate, undefined, '[]');
      });

      return result;
    };

    const ytdStartDate = currentDate.clone().startOf('year');
    const oneYearStartDate = currentDate.clone().subtract(1, 'year');
    const fiveYearsStartDate = currentDate.clone().subtract(5, 'years');

    const periods = {
      ytd: hasDataForPeriod(ytdStartDate),
      oneYear: hasDataForPeriod(oneYearStartDate),
      fiveYears: hasDataForPeriod(fiveYearsStartDate),
      all: Object.keys(dhsEmissions).length > 0,
    };

    return periods;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const { metricsCurrencyList, metricsViewCurrency } = emissionsStore;
  const data = filterEmissionsData(timeline);

  const currencyOptions = metricsCurrencyList.map((currency) => ({
    value: currency,
    label: currency.toUpperCase(),
  }));

  const longestLabelLength = data
    .map(data => data.date)
    .reduce((acc, cur) => (cur.length > acc ? cur.length : acc), 0);

  const availablePeriods = getAvailablePeriods();
  const ytdStartDate = moment().startOf('year');
  const oneYearStartDate = moment().subtract(1, 'year');
  const fiveYearsStartDate = moment().subtract(5, 'years');
  const currentDate = moment();

  return (
    <div className="balance banner">
      <div className="balance-top">
        <h1>Velocity of money</h1>
        {metricsCurrencyList.length > 1 && (
          <Select
            className="currency-select"
            classNamePrefix="select"
            menuPlacement="auto"
            options={currencyOptions}
            onChange={handleCurrencyChange}
            defaultValue={currencyOptions.find((option) => option.value === metricsViewCurrency) || currencyOptions[0]}
          />
        )}
      </div>

      <div className="balance-nav-wrap mar__t-16">
        <div className="balance-nav">
          {availablePeriods.ytd && (
            <div
              className={`balance-nav-btn ${activeNavId === "ytd" ? "active" : ""}`}
              onClick={() => getEmissionsByDate(currentDate.diff(ytdStartDate, 'days'), "ytd")}
            >
              YTD
            </div>
          )}
          {availablePeriods.oneYear && (
            <div
              className={`balance-nav-btn ${activeNavId === "1y" ? "active" : ""}`}
              onClick={() => getEmissionsByDate(currentDate.diff(oneYearStartDate, 'days'), "1y")}
            >
              1y
            </div>
          )}
          {availablePeriods.fiveYears && (
            <div
              className={`balance-nav-btn ${activeNavId === "5y" ? "active" : ""}`}
              onClick={() => getEmissionsByDate(currentDate.diff(fiveYearsStartDate, 'days'), "5y")}
            >
              5y
            </div>
          )}
          {availablePeriods.all && (
            <div
              className={`balance-nav-btn ${activeNavId === "all" ? "active" : ""}`}
              onClick={() => getEmissionsByDate(null, "all")}
            >
              All
            </div>
          )}
        </div>
      </div>

      <ResponsiveContainer width="100%" height={420} minHeight={420}>
        <AreaChart
          height={420}
          width={500}
          data={data}
          margin={{top: 10, right: 20, left: 10, bottom: 0 }}
          left={longestLabelLength}
        >
          <CartesianGrid vertical={false} stroke="#ACB0BD54" />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="2%" stopColor="rgba(28, 119, 195, 0.5)" stopOpacity={1} />
              <stop offset="98%" stopColor="rgba(28, 119, 195, 0)" stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <XAxis dy={8} dataKey="date" />
          <YAxis dx={-6} dataKey="amount" />
          <Tooltip
            wrapperStyle={{ background: "#000314" }}
            contentStyle={{ color: "white", background: "#000314" }}
            itemStyle={{ color: "white", background: "#000314" }}
            labelFormatter={(label) => data.find(d => d.date === label)?.fullDate}
          />
          <Area
            dot={{ stroke: "white", strokeWidth: 2 }}
            type="linear"
            dataKey="amount"
            stroke="#8BE5DD"
            strokeWidth={2}
            fill="url(#colorUv)"
            animationBegin={400}
            animationEasing="ease-in-out"
            isAnimationActive={true}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default inject("emissionsStore")(observer(ViewMetrics));
